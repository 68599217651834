import { SvgIcon } from '@components/SvgIcon';
import { LocalizedText, Typography } from '@components/Typography';
import { FC } from 'react';

type BillingPlanItemProps = LocalizedText;

export const BillingPlanItem: FC<BillingPlanItemProps> = (textProps) => (
  <li className="flex items-center gap-1.5  ">
    <SvgIcon name="CheckmarkGradient" />
    <Typography {...textProps} variant="sm/bold" className="text-grey-900 first-letter:capitalize" />
  </li>
);
