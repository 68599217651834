import { api } from '@/api/apiV2';
import { useFeatureQuery } from '@/hooks';
import { QueryKeys } from '../queryKeys';

type useTeamMetricsArgs = {
  companyId: string;
  signal: AbortSignal;
};

export const useTeamMetrics = ({ companyId, signal }: useTeamMetricsArgs) => {
  const { isUnlocked, ...rest } = useFeatureQuery({
    queryKey: [QueryKeys.TeamMetrics, companyId],
    queryFn: async () => {
      const result = await api.teams.getCompanyTeamMetrics(companyId, {
        signal,
      });

      return result.data;
    },
  });

  return {
    ...rest,
    isTeamUnlocked: isUnlocked,
  };
};
