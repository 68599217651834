import * as Popover from '@radix-ui/react-popover';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

export const MultiSelect = ({
  options,
  selectedItems,
  handleToggleItem,
  inputClassName,
  optionLabelClassName,
  isSearchable,
}) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const searchedOptions = useMemo(() => {
    return isSearchable
      ? options
          .filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => {
            const isASelected = selectedItems.includes(a);
            const isBSelected = selectedItems.includes(b);

            return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
          })
      : options;
  }, [isSearchable, options, search, selectedItems]);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={twMerge(
            'border-1 select bordered select-sm h-10 w-full items-center border-base-300 bg-base-200 text-xs',
            inputClassName,
          )}
        >
          {renderText(selectedItems, t)}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="z-[10005] w-[200px] rounded-lg bg-base-200 p-2 px-4 pb-4 text-xs shadow-md"
          sideOffset={10}
          align="start"
          side="bottom"
        >
          {isSearchable ? (
            <div className="flex flex-col pb-3 pt-2">
              <label
                className={
                  'flex w-full appearance-none items-center gap-2 rounded-full bg-base-200 px-4 py-2 text-sm shadow-md outline-none'
                }
              >
                <IoSearchOutline size={16} />
                <input
                  type="text"
                  className="w-full bg-base-200 outline-none"
                  value={search}
                  autoComplete="on"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
          ) : null}
          <div className="h-fit max-h-[160px] overflow-y-auto">
            {searchedOptions.length > 0
              ? searchedOptions.map((item) => (
                  <div key={item.label} className="my-2 flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedItems.findIndex((option) => option.value === item.value) !== -1}
                      onChange={() => handleToggleItem(item)}
                      className="checkbox size-[17px] rounded-md"
                    />
                    <label className={twMerge('ml-2', optionLabelClassName)}>{t(item.label)}</label>
                  </div>
                ))
              : 'No options'}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const renderText = (items, t) => {
  if (items.length === 0) {
    return <span className="opacity-70">Select...</span>;
  } else if (items.length === 1) {
    return t(items[0].label);
  } else {
    return `${items.length} selected`;
  }
};
