import { api } from '@/api/apiV2';
import { UserListUpdateRequest } from '@/api/generated/Api';
import { useMutation } from '@tanstack/react-query';
import { MutationKeys } from '../queryKeys';

type UseUpdateUserListArgs = {
  id: string;
  signal?: AbortSignal;
  onSuccess?: (data: UserListUpdateRequest) => void;
};

export const useUpdateUserList = ({ id, signal, onSuccess }: UseUpdateUserListArgs) => {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: [MutationKeys.UpdateUserList],
    mutationFn: async (data: UserListUpdateRequest) => {
      await api.userList.userListsUpdate(id, data, { signal });

      onSuccess?.(data);
    },
  });

  return {
    updateUserList: mutateAsync,
    isUserListUpdating: isPending,
  };
};
