import { DefinedInitialDataInfiniteOptions, InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';

type InfiniteQueryResponse<T> = {
  hasNextPage: boolean;
  data: T[];
};

type InfiniteFeatureQueryResponse<T> = InfiniteQueryResponse<T> & {
  isUnlocked?: boolean;
};

type QueryFnArgs = {
  pageParam: number;
};

type UseInfiniteFeatureQueryArgs<TData> = {
  queryKey: QueryKey;
  queryFn: (args: QueryFnArgs) => Promise<InfiniteFeatureQueryResponse<TData> | undefined>;
  options?: Omit<
    DefinedInitialDataInfiniteOptions<
      InfiniteFeatureQueryResponse<TData> | undefined,
      unknown,
      InfiniteData<InfiniteFeatureQueryResponse<TData>>,
      QueryKey,
      number
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam' | 'getPreviousPageParam'
  >;
  statusCodes?: number[];
};

export const useInfiniteFeatureQuery = <TData>({
  queryKey,
  queryFn,
  options,
  statusCodes = [409],
}: UseInfiniteFeatureQueryArgs<TData>) => {
  const queryResult = useInfiniteQuery({
    ...options,
    queryKey,
    queryFn: async ({ pageParam }) => {
      try {
        return await queryFn({ pageParam });
      } catch (error: any) {
        if (statusCodes.includes(error.response?.status)) {
          return {
            data: [],
            hasNextPage: false,
            isUnlocked: false,
          };
        }

        return {
          data: [],
          hasNextPage: false,
          isUnlocked: true,
        };
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage?.hasNextPage) {
        return lastPageParam + 1;
      }

      return undefined;
    },
    getPreviousPageParam: (_, _allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }

      return firstPageParam - 1;
    },
  });

  return {
    ...queryResult,
  };
};
