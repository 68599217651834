import { COMPANY_ENTITY_TYPES } from '@/data/options';
import { customAxios } from './customAxios';

const PATH = '/api/companies';
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export function httpGetCompanyInfo(companyId) {
  return customAxios.get(`${API_ENDPOINT}${PATH}/${companyId}`);
}

export function httpGetSimilarCompanies(companyId, data) {
  return customAxios.post(`${API_ENDPOINT}${PATH}/${companyId}/similar-companies`, data);
}

export function httpCreateCompanyNotes(companyId, title, content) {
  return customAxios.post(`${API_ENDPOINT}${PATH}/${companyId}/notes`, {
    userId: localStorage.getItem('userId'),
    title: title,
    content: content,
  });
}

export function httpGetCompanyNotes(companyId) {
  return customAxios.get(`${API_ENDPOINT}${PATH}/${companyId}/notes?userId=${localStorage.getItem('userId')}`);
}

export function httpDeleteCompanyNotes(companyId, noteId) {
  return customAxios.delete(`${API_ENDPOINT}${PATH}/${companyId}/notes/${noteId}`);
}

export function httpUpdateCompanyNotes(companyId, noteId, title, content) {
  return customAxios.put(`${API_ENDPOINT}${PATH}/${companyId}/notes/${noteId}`, {
    userId: localStorage.getItem('userId'),
    title: title,
    content: content,
  });
}

export function httpSearchCompanies(formData, apiEndpoint) {
  const {
    query,
    type,
    foundedFrom,
    foundedTo,
    foundedFromChanged,
    foundedToChanged,
    relevantScore,
    selectedRegion,
    companySize,
    startup,
    funding,
    fundingStages,
    offset,
    limit,
    tags,
    companyType,
    targetMarkets,
  } = formData;

  let foundedFromBeforeCall = null;
  let foundedToBeforeCall = null;

  if (foundedFrom === 1900) {
    if (foundedFromChanged === true) {
      foundedFromBeforeCall = 1900;
    }
  } else {
    foundedFromBeforeCall = foundedFrom;
  }

  if (foundedTo === 2024) {
    if (foundedToChanged === true) {
      foundedToBeforeCall = 2024;
    }
  } else {
    foundedToBeforeCall = foundedTo;
  }

  const requestObject = {
    query,
    isVectorSearch: !type,
    foundedFrom: foundedFromBeforeCall,
    foundedTo: foundedToBeforeCall,
    scoreFrom: relevantScore[0] !== 0 ? relevantScore[0] / 100 : undefined,
    country: selectedRegion.value !== 'All' && selectedRegion ? selectedRegion.label : undefined,
    companySize: companySize.length > 0 ? companySize.map((item) => item.label) : undefined,
    isStartup: startup ? true : undefined,
    isFoundingInfoExist: funding ? true : undefined,
    foundingStages: fundingStages.length > 0 ? fundingStages.map((item) => item.label) : undefined,
    limit: limit,
    offset: offset * limit,
    tags: tags?.length > 0 ? tags?.map((item) => item?.label.replace(/\s/g, '')) : undefined,
    entityType: COMPANY_ENTITY_TYPES.includes(companyType?.value) ? 'Company' : companyType?.value,
    entityTypeL2: COMPANY_ENTITY_TYPES.includes(companyType?.value) ? companyType?.value : null,
    targetMarkets: targetMarkets?.length > 0 ? targetMarkets?.map((item) => item?.label) : undefined,
  };

  return customAxios.post(`${API_ENDPOINT}${PATH}${apiEndpoint}`, requestObject);
}

export function httpSaveSearchToMyArea(formData, title, signalTypes) {
  const {
    query,
    type,
    foundedFrom,
    foundedTo,
    foundedFromChanged,
    foundedToChanged,
    relevantScore,
    selectedRegion,
    companySize,
    startup,
    funding,
    fundingStages,
    offset,
    limit,
    tags,
    companyType,
    targetMarkets,
  } = formData;

  let foundedFromBeforeCall = null;
  let foundedToBeforeCall = null;

  if (foundedFrom === 1900) {
    if (foundedFromChanged === true) {
      foundedFromBeforeCall = 1900;
    }
  } else {
    foundedFromBeforeCall = foundedFrom;
  }

  if (foundedTo === 2024) {
    if (foundedToChanged === true) {
      foundedToBeforeCall = 2024;
    }
  } else {
    foundedToBeforeCall = foundedTo;
  }

  const requestObject = {
    query,
    isVectorSearch: !type,
    foundedFrom: foundedFromBeforeCall,
    foundedTo: foundedToBeforeCall,
    scoreFrom: relevantScore[0] !== 0 ? relevantScore[0] / 100 : undefined,
    country: selectedRegion.value !== 'All' && selectedRegion ? selectedRegion.label : undefined,
    companySize: companySize.length > 0 ? companySize.map((item) => item.label) : undefined,
    isStartup: startup ? true : undefined,
    isFoundingInfoExist: funding ? true : undefined,
    foundingStages: fundingStages.length > 0 ? fundingStages.map((item) => item.label) : undefined,
    title,
    signalTypes,
    limit: limit,
    offset: offset * limit,
    tags: tags?.length > 0 ? tags?.map((item) => item?.label.replace(/\s/g, '')) : undefined,
    entityType: COMPANY_ENTITY_TYPES.includes(companyType?.value) ? 'Company' : companyType?.value,
    entityTypeL2: COMPANY_ENTITY_TYPES.includes(companyType?.value) ? companyType?.value : null,
    targetMarkets: targetMarkets?.length > 0 ? targetMarkets?.map((item) => item?.label) : undefined,
  };

  return customAxios.post(`${API_ENDPOINT}${PATH}/search`, requestObject);
}
