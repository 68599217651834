import { InfoCard } from '@global/components/InfoCard';
import { FC, ReactNode } from 'react';
import { SignalCardBody, SignalCardFooter, SignalCardHeader } from './parts';
import { SignalCardProps as BaseSignalCardProps } from './types';

type SignalCardProps = {
  header?: ReactNode;
} & BaseSignalCardProps;

export const SignalCard: FC<SignalCardProps> = ({ header, signal }) => {
  return (
    <InfoCard className="gap-[13px] grid grid-cols-1 grid-rows-subgrid row-span-2">
      <SignalCardHeader signal={signal}>{header}</SignalCardHeader>
      <div className="flex grow flex-col gap-1.5">
        <SignalCardBody signal={signal} />
        <SignalCardFooter signal={signal} />
      </div>
    </InfoCard>
  );
};
