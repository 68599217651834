import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { LocalizedText, Typography } from '@components/Typography';
import { VariantProps } from 'class-variance-authority';
import { FC } from 'react';
import { BILLING_MODAL_NOTIFICATION_ICONS } from './constants';
import { styles } from './styles';

type BillingModalNotificationProps = {
  intent?: Exclude<VariantProps<typeof styles>['intent'], null | undefined>;
  className?: string;
} & LocalizedText;

export const BillingModalNotification: FC<BillingModalNotificationProps> = ({
  intent = 'warning',
  className,
  ...typographyProps
}) => (
  <header className={cn(styles({ intent }), className)}>
    <SvgIcon name={BILLING_MODAL_NOTIFICATION_ICONS[intent]} />
    <Typography {...typographyProps} variant="xs/bold" className="first-letter:capitalize" />
  </header>
);
