import { SignalResponse } from '@/api/generated/Api';
import { RoutePath } from '@/router/constants';
import { InfoCard } from '@global/components/InfoCard';
import { FC, PropsWithChildren, ReactNode } from 'react';

type SignalCardBodyProps = {
  signal: SignalResponse;
  header?: ReactNode;
} & PropsWithChildren;

export const SignalCardBody: FC<SignalCardBodyProps> = ({ signal, header, children }) => (
  <InfoCard.Body className="gap-3">
    <InfoCard.Header
      image={signal.company.logoUrl}
      title={signal.company.companyName ?? '-'}
      link={RoutePath.COMPANY.replace(':id', signal.company.companyId)}
    >
      {header}
    </InfoCard.Header>
    <InfoCard.Description>
      <InfoCard.DescriptionItem text="foundedAt" interpolationsParams={{ year: signal.company.foundedIn ?? '-' }} />
      <InfoCard.DescriptionItem
        text="hq"
        interpolationsParams={{ country: signal.company.headquarters?.city ?? '-' }}
      />
      {signal.company.foundingInfo?.lastRoundType && signal.company.foundingInfo?.lastRoundAmountRaised ? (
        <InfoCard.DescriptionItem
          arbitrary
          text={`${signal.company.foundingInfo.lastRoundType}: ${signal.company.foundingInfo?.lastRoundAmountRaised}`}
          className="shrink-0"
        />
      ) : null}
    </InfoCard.Description>
    <InfoCard.Content>{children}</InfoCard.Content>
  </InfoCard.Body>
);
