import { cn } from '@/lib/utils';
import { BaseCheckbox } from '@components/Checkbox';
import { FC } from 'react';
import { BillingPlanRadioCardProps } from './types';

export const BillingPlanRadioCard: FC<BillingPlanRadioCardProps> = ({
  id,
  checked,
  onChange,
  classNames,
  children,
  checkboxProps,
}) => {
  return (
    <label
      htmlFor={id}
      aria-selected={checked}
      aria-disabled={!onChange}
      className={cn(
        'group relative h-36 w-[170px] cursor-pointer rounded-[0.875rem] border border-grey-900 bg-grey-100 p-4 aria-disabled:cursor-not-allowed aria-disabled:opacity-50 aria-selected:border-primary-600 aria-selected:bg-grey-300 motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none',
        classNames?.wrapper,
      )}
    >
      <div
        className={cn(
          'grid h-full grid-cols-1 grid-rows-[auto,auto,1fr] items-end gap-y-2 *:text-grey-900 *:first-letter:capitalize *:group-aria-selected:text-primary-600',
          classNames?.content,
        )}
      >
        {children}
      </div>
      {onChange ? (
        <BaseCheckbox
          id={id}
          {...checkboxProps}
          checked={checked}
          onChange={(e) => onChange(id, e)}
          wrapperClassName={cn(
            'rounded-full group-aria-selected:visible invisible absolute -top-2 -right-2 size-7	has-[:checked]:p-[14px] group-hover:p-[14px] group-aria-[selected=false]:motion-safe:duration-0',
            checkboxProps?.wrapperClassName,
          )}
          checkProps={{
            className: 'size-7',
          }}
          className={cn('rounded-full', checkboxProps?.className)}
        />
      ) : null}
    </label>
  );
};
