import { useUpdateSignalTypes } from '@/api/entities/queries/useUpdateSignalTypes';
import { QueryKeys } from '@/api/entities/queryKeys';
import { QueryResponse, SignalType } from '@/api/generated/Api';
import { useAbortController } from '@/hooks';
import { BaseModal } from '@components/BaseModal/BaseModal';
import { MultiSelect } from '@components/MultiSelect';
import { Typography } from '@components/Typography';
import { SIGNAL_TYPES_OPTIONS } from '@global/constants/options';
import { queryClient } from '@global/queryClient';
import { Option } from '@global/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { UpdateQuerySignalTypesFormSchema, updateQuerySignalTypesValidation } from './validator';

type EditQueryModalProps = {
  query: QueryResponse | null;
  onClose: VoidFunction;
};

export const EditQueryModal: FC<EditQueryModalProps> = ({ query, onClose }) => {
  const { signal } = useAbortController();

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<UpdateQuerySignalTypesFormSchema>({
    resolver: zodResolver(updateQuerySignalTypesValidation),
    mode: 'onChange',
    defaultValues: {
      signalTypes: SIGNAL_TYPES_OPTIONS.filter((type) => query?.signalTypes?.includes(type.value as SignalType)),
    },
  });

  const { updateSignalTypes, isSignalTypesUpdating } = useUpdateSignalTypes({
    queryId: query?.id ?? '',
    signal,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Queries],
      });
      onClose();
    },
  });

  const onSubmit: SubmitHandler<UpdateQuerySignalTypesFormSchema> = (data) => {
    updateSignalTypes({
      signalTypes: data.signalTypes.map((option) => option.value as SignalType),
    });
  };

  return (
    <BaseModal isOpen={!!query} onCloseHandler={onClose}>
      <form className="contents" onSubmit={handleSubmit(onSubmit)}>
        <Typography text={'editQuery'} tag="h2" variant="doubleXl/bold" className="capitalize text-grey" />
        <input type="text" value={query?.title ?? ''} className="input input-bordered w-full" readOnly />
        <Controller
          name="signalTypes"
          control={control}
          render={({ field }) => (
            <MultiSelect
              options={SIGNAL_TYPES_OPTIONS}
              selectedItems={field.value}
              handleToggleItem={(value: Option<string>) => {
                const newSelectedItems = field.value;
                const index = newSelectedItems.findIndex((item) => item.value === value.value);

                if (index !== -1) {
                  newSelectedItems.splice(index, 1);
                } else {
                  newSelectedItems.push(value);
                }

                field.onChange(newSelectedItems);
              }}
              inputClassName="capitalize h-12"
              optionLabelClassName="capitalize"
              isSearchable={false}
            />
          )}
        />
        <footer className="flex items-center justify-center gap-4">
          <button
            type="submit"
            disabled={!isDirty || Object.keys(errors).length > 0}
            className='transition-transform" btn flex w-1/2 items-center bg-custom-gradient text-base-200 shadow-md hover:scale-102 disabled:bg-neutral'
          >
            {isSignalTypesUpdating ? (
              <span className="loading loading-dots loading-md"></span>
            ) : (
              <div className="flex items-center gap-2">
                <FaSave size={20} />
                <Typography text="save" variant="lg/medium" className="capitalize" />
              </div>
            )}
          </button>
        </footer>
      </form>
    </BaseModal>
  );
};
