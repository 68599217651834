import { useBillingPlans, useUserSubscriptionState } from '@/api/entities/subscriptions';
import { useAbortController } from '@/hooks';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { FC, useState } from 'react';
import { BillingModal } from '../BillingModal';
import { BILLING_FEATURES_DATA } from '../constants';
import { BillingFeatureEnumType } from '../types';

type BaseCreditsInfoProps = {
  feature: BillingFeatureEnumType;
};

export const CreditsInfo: FC<BaseCreditsInfoProps> = ({ feature }) => {
  const data = BILLING_FEATURES_DATA.get(feature);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { signal } = useAbortController();
  const { subscriptionState, isUserSubscriptionStateLoading } = useUserSubscriptionState({
    signal,
    onSuccess: (data) => {
      if (data?.remainingCredits === 0) {
        setIsModalOpen(true);
      }
    },
  });

  const { billingPlans, isBillingPlansLoading } = useBillingPlans({ signal });

  const currentPlan = billingPlans?.find((plan) => plan.id === subscriptionState?.id)!;
  const nextPlan = billingPlans?.[(billingPlans?.findIndex((x) => x.id === subscriptionState?.id) ?? 0) + 1];

  return (
    <>
      <div className="flex min-w-20 items-center gap-2 rounded-full border bg-[#e6e6e6] px-2 py-1">
        <Loadable
          isLoading={isUserSubscriptionStateLoading || isBillingPlansLoading}
          spinnerProps={{
            size: 'xs',
          }}
        >
          {data ? <SvgIcon name={data.icon} className="size-4" /> : null}
          <Typography
            arbitrary
            text={`${subscriptionState?.remainingCredits}/${subscriptionState?.monthlyCredits}`}
            variant="sm/bold"
            className="leading-[14px] text-grey-700"
          />
        </Loadable>
      </div>
      {isModalOpen ? (
        <BillingModal
          headerProps={{
            text: 'youHaveReachedLimit',
            interpolationsParams: {
              currentPlan: currentPlan.planName,
              nextPlan: nextPlan?.planName ?? '',
            },
          }}
          onCloseHandler={() => setIsModalOpen(false)}
          showUpgradeOnly
        />
      ) : null}
    </>
  );
};
