import { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaLinkedin } from 'react-icons/fa';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { MIN_OFFSET, Pagination } from './Pagination';

const CompaniesTableMini = ({ data, totalRecords }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (data?.length > 0) {
      setLoading(false);
    }
  }, [data]);

  const [searchParams, setSearchParams] = useSearchParams();

  const setPage = (newOffset) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: newOffset,
    });
  };

  const setPageSize = (limit) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      offset: MIN_OFFSET,
      limit: limit,
    });
  };

  const handleClick = (event, path) => {
    event.stopPropagation();
    event.preventDefault();

    const url = `${window.location.origin}${path}`;

    window.open(url, '_blank');
  };

  return (
    <div className="bg:base-100 relative z-[0] flex w-full flex-col">
      {/** Table */}
      {loading ? (
        <div className="flex h-96 items-center justify-center">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        <>
          <div className="max-h-[calc(40vh)] overflow-x-auto">
            <table className="table table-pin-rows min-w-full bg-base-100 text-left text-sm text-base-content">
              <thead className="border-3 bg-base-100 text-xs uppercase">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Similarity Score
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Logo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Links
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Latest Funding
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Founding Year
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company Size
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Country
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {data?.map((row, index) => (
                  <tr
                    key={row.companyId + '_' + index.toString()}
                    className="cursor-pointer border-b border-neutral bg-base-200 hover:bg-base-100"
                    onClick={(event) => handleClick(event, `/company/${row.companyId}?tab=overview`)}
                  >
                    <td className="flex gap-5 px-6 py-3 text-primary-600">
                      {row.score ? (row.score * 100).toFixed(1) : 0}%
                      <span>
                        <progress
                          className="text- progress progress-primary w-20"
                          value={row.score ? row.score * 100 : 0}
                          max="100"
                        ></progress>
                      </span>
                    </td>
                    <td className="px-6 py-3">
                      <img
                        src={row.logoUrl}
                        onError={(e) => (e.target.src = '/staticcompany.png')}
                        alt="logo"
                        className="h-8 w-8 rounded-full border"
                      />
                    </td>
                    <td className="px-6 py-3">{row.companyName}</td>
                    {/*Linkedin and Website */}
                    <td className="px-6 py-3">
                      <div className="flex items-center gap-3 text-base-content">
                        <a
                          href={row.linkedinUrl}
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          rel="noreferrer"
                        >
                          <FaLinkedin size={20} />
                        </a>
                        <a
                          href={row.websiteUrl}
                          target="_blank"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          rel="noreferrer"
                        >
                          <FaExternalLinkAlt size={18} />
                        </a>
                      </div>
                    </td>

                    <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{row.shortDescription}</div>
                    </td>
                    <td className="overflow-hidden px-6 py-3" style={{ maxHeight: '80px' }}>
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-center">
                        {row?.lastRoundType || '-'} <br />{' '}
                        <div className="text-xs opacity-70">{row?.lastRoundAmountRaised}</div>
                      </div>
                    </td>
                    <td className="px-6 py-3">{row.foundedIn}</td>
                    <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">
                      {row.employeeRange}
                    </td>
                    <td className="px-6 py-3">{row.countryName}</td>
                    <td className="overflow-hidden overflow-ellipsis whitespace-nowrap px-6 py-3">
                      {row.headquartersFullAddress}
                    </td>
                    <td className="px-6 py-3"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination total={totalRecords} setLimit={setPageSize} setPage={setPage} />
        </>
      )}
    </div>
  );
};

export default CompaniesTableMini;
