import { Button } from '@components/Button';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Dispatch, FC, FormEventHandler, SetStateAction } from 'react';

type CheckoutFormProps = {
  onSuccess?: () => Promise<void>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
};

export const CheckoutForm: FC<CheckoutFormProps> = ({ onSuccess, isProcessing, setIsProcessing }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        save_payment_method: true,
      },
      redirect: 'if_required',
    });

    await onSuccess?.();
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flex flex-col items-center gap-5">
      <PaymentElement />
      <Button isLoading={isProcessing || !stripe || !elements} text="payNow" type="submit" className="w-1/2" />
    </form>
  );
};
