import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';

type BaseModalProps = {
  isOpen: boolean;
  onCloseHandler: VoidFunction;
  renderTo?: string;
};

export const BaseModal: FC<PropsWithChildren<BaseModalProps>> = ({
  isOpen,
  children,
  onCloseHandler,
  renderTo = 'global-modals-container',
}) => {
  const layout = (
    <div
      data-isopen={isOpen}
      className="fixed inset-0 hidden bg-black-500/50 outline-0 data-[isopen=true]:z-[9999] data-[isopen=true]:flex data-[isopen=true]:items-center data-[isopen=true]:justify-center"
    >
      <div className="w-[80%] rounded-lg bg-base-200 lg:w-[50%]">
        <header className="flex h-10 w-full items-center justify-end rounded-tl-lg rounded-tr-lg bg-custom-gradient px-2">
          <button onClick={onCloseHandler}>
            <IoClose size={20} className="text-base-200" />
          </button>
        </header>
        <div className="flex flex-col gap-6 p-10">{children}</div>
      </div>
    </div>
  );

  const container = document.getElementById(renderTo);
  return container ? createPortal(layout, container) : layout;
};
