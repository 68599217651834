import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../queryKeys';

type UseAttachPaymentMethodArgs = {
  onSubmit?: VoidFunction;
};

export const useAttachPaymentMethod = ({ onSubmit }: UseAttachPaymentMethodArgs) => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationKey: [MutationKeys.AttachPaymentMethod],
    mutationFn: async (id: string) => {
      await api.paymentMethods.attachToUser(id);

      onSubmit?.();
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.PaymentMethods],
      });
    },
  });

  return {
    isPaymentMethodAttaching: isPending,
    attachPaymentMethod: mutateAsync,
  };
};
