import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Message, SendMessageRequest, SendMessageResponse, GetMessageResponse } from './types';

const PATH = '/api/v2/agent';
const chatApi = axios.create({
  baseURL: import.meta.env.VITE_AGENT_API_ENDPOINT,
});

const QueryKeys = {
  ChatMessages: 'chat-messages',
};

export const useChatMessages = (threadId: string) => {
  return useQuery<Message[], Error>({
    queryKey: [QueryKeys.ChatMessages, threadId],
    queryFn: async () => {
      try {
        const response = await chatApi.post<GetMessageResponse>(`${PATH}/threads/messages`, {
          thread_id: threadId,
        });
        return response.data.messages;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          return [];
        }
        throw error;
      }
    },
    staleTime: Infinity,
  });
};

export const useSendMessage = () => {
  const queryClient = useQueryClient();

  return useMutation<SendMessageResponse, Error, SendMessageRequest>({
    mutationFn: async ({ threadId, companyId, question }) => {
      const response = await chatApi.post<SendMessageResponse>(`${PATH}/threads`, {
        company_id: companyId,
        question,
        thread_id: threadId,
      });
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QueryKeys.ChatMessages, variables.threadId], data.messages);
    },
  });
};
