import { useCompany } from '@/api/entities/companies';
import { useUserInfo } from '@/api/entities/accounts/useUserInfo';
import { useAbortController } from '@/hooks';
import { Avatar } from '@components/Avatar';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { FC, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { SvgIcon } from '@components/SvgIcon';
import { Button } from '@components/Button';
import ChatComponent from '../../../../components/ChatComponent';
import { useTeamMembers } from '@/api/entities/teams';
import { useUnlockTeam } from '@/api/entities/teams/useUnlockTeam';
import { BillingFeatureGate } from '@global/components/Billing';

export const Assistant: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { unlockTeam } = useUnlockTeam();

  const { userInfo, isUserInfoLoading } = useUserInfo({
    signal,
  });

  const { company, isFetching } = useCompany({
    companyId: id as string,
    signal,
  });

  const { isEmpty, isFetched, isUnlocked, isTeamMembersFetching } = useTeamMembers({
    companyId: id as string,
    companyName: company?.companyName,
    signal,
  });

  const [threadId, setThreadId] = useState<string | null>(localStorage.getItem('thread_id'));

  const handleStartChatting = () => {
    const currentDateTime = new Date().toLocaleString();
    const newThread = `${company?.companyName} - ${userInfo?.firstName} ${userInfo?.lastName ?? ''} - ${currentDateTime}`;
    localStorage.setItem('thread_id', newThread);
    setThreadId(newThread);
  };

  const handleResetChat = () => {
    localStorage.removeItem('thread_id');
    setThreadId(null);
  };

  useEffect(() => {
    setThreadId(localStorage.getItem('thread_id'));
  }, []);

  return (
    <div className="flex h-[calc(100vh-160px)] w-full flex-col">
      <header className="flex w-full items-center justify-between p-4">
        <div className="flex items-center justify-start gap-2">
          <Loadable isLoading={isFetching} spinnerProps={{ size: 'sm' }} className="">
            <Avatar src={company?.logoUrl} className="size-[30px]" />
            <Typography
              arbitrary
              text={company?.companyName ?? '-'}
              variant="lg/bold"
              className="leading-8 text-grey-900 first-letter:capitalize"
            />
          </Loadable>
        </div>
        {threadId !== 'undefined' && company?.companyName && threadId?.includes(company?.companyName) && (
          <Button text="Reset Chat" onClick={handleResetChat} intent="empty" />
        )}
      </header>
      {threadId !== 'undefined' && company?.companyName && threadId?.includes(company?.companyName) && id ? (
        <div className="flex-grow overflow-hidden bg-base-200 p-8 pb-4">
          <ChatComponent companyId={id} threadId={threadId} />
        </div>
      ) : isTeamMembersFetching ? (
        <div className="relative flex h-full w-full flex-col">
          <div className="flex-1 overflow-hidden">
            <div className="flex h-full items-center justify-center">
              <span className="loading loading-dots loading-md"></span>
            </div>
          </div>
        </div>
      ) : isFetched && !isUnlocked ? (
        <BillingFeatureGate
          icon={<SvgIcon name={'Assistante'} className="h-12 w-12" />}
          feature="Chat"
          onUnlock={async () => await unlockTeam(id as string)}
          title={{
            text: 'chat_feature_title',
            interpolationsParams: { companyName: company?.companyName ?? '-' },
          }}
          description={{
            text: 'chat_feature_description',
          }}
          unlockWarning={{
            text: 'chat_feature_unlockWarning',
          }}
          find={{
            text: 'chat_feature_find',
          }}
          whereToFind=""
        />
      ) : isFetched && isUnlocked ? (
        <div className="flex flex-grow flex-col items-center justify-center gap-3 rounded-lg bg-grey-300">
          <SvgIcon name={'Assistante'} className="h-12 w-12" />
          <Typography arbitrary text={`Chat with Yuno AI Assistant`} variant="lg/bold" className="text-grey-600" />
          <Button text="Start Chatting" className="mt-1 px-12 py-2 text-sm" onClick={handleStartChatting} />
        </div>
      ) : null}
    </div>
  );
};
