import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { MIN_OFFSET } from '@components/Pagination';
import { DEFAULT_LIMIT, MAX_LIMIT, MIN_LIMIT } from '@components/Pagination/constants';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { IoSearchOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { httpGetUserCompanies } from '../api/UserListsAPI';
import AddToListPopup from '../components/AddToListPopup';
import CompaniesTable from '../components/CompaniesTable';
import SaveQueryPopup from '../global/components/SaveQueryModal/SaveQueryPopup';
import useSearchForm from '../hooks/useSearchForm';

const MyCompanies = () => {
  const Navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const [showSaveQueryPopup, setShowSaveQueryPopup] = useState(false);
  const [allCompanies, setAllCompanies] = useState({
    data: [],
    totalRecords: 0,
  });

  const {
    formData,
    handleChangeQuery,
    handleChangeToggle,
    isQueryValid,
    go,
    saveToMyArea,
    saved,
    isLoading,
    response,
    inputError,
    showAddToListPopup,
    setShowAddToListPopup,
    showFilters,
    selectedIds,
    setSelectedIds,
    setPage,
    setPageSize,
  } = useSearchForm(true, '/my-companies', '/search-in-lists');

  const goBack = () => {
    Navigate(-1);
  };

  const handleSaveConfirm = async (title, signalTypes) => {
    await saveToMyArea(title, signalTypes);
  };

  const offset = Math.max(MIN_OFFSET, +(searchParams.get('offset') ?? MIN_OFFSET));

  const initialLimit = searchParams.get('limit');
  const limit =
    initialLimit === null ? DEFAULT_LIMIT : Math.max(MIN_LIMIT, Math.min(+(initialLimit ?? MIN_LIMIT), MAX_LIMIT));

  useEffect(() => {
    if (!searchParams.get('query')) {
      httpGetUserCompanies({ offset: offset * limit, limit }).then((response) => {
        setAllCompanies(response.data);
      });
    }

    // Function to fetch and combine all companies
    // const fetchAllCompanies = async () => {
    //   try {
    //     const response = await httpGetUserLists();
    //     const lists = response.data.data;

    //     // Create an array of promises for fetching companies
    //     const companyPromises = lists.map((list) =>
    //       httpGetListCompanies(list.id).then((companies) => companies.data.data),
    //     );

    //     // Wait for all promises to resolve
    //     const companyResults = await Promise.all(companyPromises);

    //     // Combine all companies into a single array
    //     const allCompanies = companyResults.flat();

    //     // Function to remove duplicates based on companyId
    //     const uniqueCompanies = (companies) => {
    //       const seen = new Set();
    //       return companies.filter((company) => {
    //         const duplicate = seen.has(company.companyId);
    //         seen.add(company.companyId);
    //         return !duplicate;
    //       });
    //     };

    //     // Get unique companies
    //     const uniqueCompaniesArray = uniqueCompanies(allCompanies);

    //     // Set state with unique companies only
    //     setAllCompanies(uniqueCompaniesArray);
    //   } catch (error) {
    //     console.error('Error fetching companies:', error);
    //   }
    // };

    // fetchAllCompanies();
  }, [offset, limit, searchParams]);

  useEffect(() => {
    if (saved) {
      setShowSaveQueryPopup(false);
    }
  }, [saved]);

  useDefaultPagination();

  return (
    <div className="w-full">
      {showAddToListPopup && (
        <AddToListPopup handleModalToggle={() => setShowAddToListPopup(false)} companiesIds={selectedIds} />
      )}
      {showSaveQueryPopup && (
        <SaveQueryPopup handleClose={() => setShowSaveQueryPopup(false)} handleSaveConfirm={handleSaveConfirm} />
      )}
      {/* Form */}
      <form className="items-left flex w-full flex-col justify-center" onSubmit={go}>
        <div className="mb-5 flex w-full items-center gap-4 lg:flex-row lg:gap-10">
          {/* Query */}
          <div className="flex flex-col lg:w-2/3 lg:flex-grow">
            <label
              input="submit"
              className={
                'input flex w-full items-center gap-2 rounded-full bg-base-200 p-6 text-sm shadow-md outline-none lg:py-1 ' +
                `${inputError && 'border-1 border-error'}`
              }
            >
              <IoSearchOutline size={16} />
              <input
                type="text"
                placeholder="Search in your companies..."
                className="grow bg-base-200"
                name="query"
                value={formData.query}
                maxLength={500}
                autoComplete="off"
                onBlur={isQueryValid}
                onChange={handleChangeQuery}
              />
            </label>
            {inputError && (
              <div className="items-left mt-2">
                <p className="text-left text-[12px] text-error">{inputError}</p>{' '}
              </div>
            )}
          </div>
          {/* Search */}
          <button type="submit" className="btn btn-primary h-10 w-20 rounded-full shadow-md lg:w-40">
            Search
          </button>
          {/* Save */}
          {/* <button
            className={`flex items-center justify-center shadow-md rounded-full h-10 w-10 tooltip tooltip-primary ${
              saved || formData.type ? 'bg-base-100' : 'bg-base-200'
            }`}
            onClick={handleSaveClick}
            disabled={saved || formData.type}
            data-tip="Save to My Area"
          >
            {saved ? (
              <IoMdCheckbox size={20} className={saved || formData.type ? 'text-gray-300' : 'text-primary'} />
            ) : (
              <IoMdSave size={20} className={saved || formData.type ? 'text-gray-300' : 'text-primary'} />
            )}
          </button> */}

          {/* Controls (Save & Semantic) */}
          <div className="flex items-center lg:flex-grow lg:gap-7">
            {/* Show/Hide Filters */}
            {/* <button
              type="button"
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center justify-center shadow-md bg-base-200 rounded-full h-10 w-10 tooltip tooltip-primary"
              data-tip="Filters"
            >
              <IoFilterSharp size={20} />
            </button> */}
            {/* Type of Search */}
            <div className="flex hidden flex-col items-center rounded-lg bg-base-200 px-4 py-2 shadow-md lg:block">
              <div className="flex w-full justify-between gap-4 text-sm">
                <div className="overflow-hidden whitespace-nowrap text-[10px]">
                  <b>AI Search</b>
                </div>
                <div className="text-[10px]">Traditional</div>
              </div>
              <div className="flex w-full justify-center">
                <input
                  type="checkbox"
                  className={`toggle ${
                    formData.type
                      ? 'border-neutral bg-neutral hover:bg-neutral hover:opacity-80'
                      : 'border-primary-600 bg-primary-600 hover:bg-primary-600 hover:opacity-80'
                  }`}
                  onChange={handleChangeToggle}
                  checked={formData.type}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Filters */}
        {/* <div
          className={`transition-opacity transition-max-height duration-400 ease-in-out overflow-x-hidden overflow-y-visible ${
            showFilters ? 'max-h-[600px] opacity-100' : 'max-h-0 opacity-0 '
          }`}
        >
          {showFilters && <Filters setFormData={setFormData} formData={formData} />}
        </div> */}
      </form>

      {!searchParams.get('query') && allCompanies.totalRecords > 0 && (
        <CompaniesTable
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          setPage={setPage}
          setPageSize={setPageSize}
          response={allCompanies}
          size={showFilters ? 'small' : 'medium'}
          displaySimilarity={false}
          displayLists={true}
        />
      )}
      {isLoading ? (
        <div className="flex items-center justify-center pt-20">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        searchParams.get('query') &&
        response?.data?.data?.length > 0 && (
          <CompaniesTable
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
            setPage={setPage}
            setPageSize={setPageSize}
            response={response.data}
            size={showFilters ? 'small' : 'medium'}
            displaySimilarity={false}
            displayLists={true}
          />
        )
      )}
      {((response?.data?.length == 0 && searchParams.get('query')) ||
        (!searchParams.get('query') && allCompanies.length == 0)) &&
        !isLoading && (
          <div className="card-elevation flex w-full flex-col items-center justify-start gap-5 bg-center p-40">
            <h2 className="text-3xl font-bold text-base-content lg:text-5xl">Ops...</h2>
            <p className="mb-4 text-lg">It seems like we haven't any companies meeting your criteria.</p>
            <button className="btn btn-primary" onClick={goBack}>
              Go back
            </button>
          </div>
        )}

      {/* Manipulate Selected */}
      {selectedIds.length > 0 && (
        <div className="fixed bottom-20 left-1/2 flex w-[60%] -translate-x-1/2 transform flex-col items-center justify-center rounded-lg bg-base-100 py-3 shadow-md sm:flex-row md:gap-4 lg:w-fit lg:px-5">
          <div className="">{selectedIds.length} selected</div>
          <div className="text-2xl font-thin text-neutral">|</div>
          <button className="btn btn-ghost" onClick={() => setShowAddToListPopup(true)}>
            <FaPlus className="bg-clip-text text-purple-500" />
            <span className="bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">
              Add to my Lists
            </span>
          </button>
          {/* <button className="btn btn-ghost">
                <MdOutlineDriveFileMove size={22} className="bg-clip-text text-purple-500 " />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500">
                  Move to other List
                </span>
              </button> */}
        </div>
      )}
    </div>
  );
};

export default MyCompanies;
