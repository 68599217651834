import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { delay } from '@utils';
import { MutationKeys, QueryKeys } from '../queryKeys';

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync, ...rest } = useMutation({
    mutationKey: [MutationKeys.CancelSubscription],
    mutationFn: async (subscriptionId: string | undefined) => {
      const response = await api.subscription.cancelSubscription({
        subscriptionId,
      });

      await delay(2000);

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.UserSubscriptionState],
      });

      return response.data;
    },
  });

  return {
    isCancellingSubscription: isPending,
    cancelSubscription: mutateAsync,
    ...rest,
  };
};
