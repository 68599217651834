import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { InfoCard } from '@global/components/InfoCard';
import { CURRENT_YEAR } from '@global/constants/shared';
import { FC } from 'react';
import { getMatchColor } from '../../../helpers';
import { SignalCardBody, SignalCardFooter, SignalCardHeader, SignalCardProps } from '../parts';
import { FilterSignalItem } from './parts';

export const NewCompanySignalCard: FC<SignalCardProps> = ({ signal }) => {
  const isQuerySignal = !!signal.body;
  // const isQuerySignal = false;

  return (
    <InfoCard className="row-span-2 grid grid-cols-1 grid-rows-subgrid gap-[13px]">
      <SignalCardHeader signal={signal} />
      <div className="flex grow flex-col gap-1.5">
        <SignalCardBody
          signal={signal}
          header={
            isQuerySignal ? (
              <Typography
                text={'matchNPercentages'}
                interpolationsParams={{ percentage: ((signal.score ?? 0) * 100).toFixed(1) }}
                variant="xs/regular"
                className={cn('truncate leading-[19px] first-letter:capitalize', getMatchColor(signal.score ?? 0))}
              />
            ) : null
          }
        >
          <InfoCard.ContentItem
            title={{
              text: isQuerySignal ? 'query' : 'filter',
              interpolationsParams: { count: 10, punctuation: ':' },
            }}
          >
            {isQuerySignal ? (
              <InfoCard.ContentItemContent text={signal.body} />
            ) : (
              <>
                {signal.filter?.scoreFrom || signal.filter?.scoreTo ? (
                  <FilterSignalItem
                    title="score"
                    content={`${signal.filter.scoreFrom ? signal.filter.scoreFrom * 100 : 0} - ${signal.filter.scoreTo ? signal.filter.scoreTo * 100 : 100}`}
                  />
                ) : null}
                {signal.filter?.companySize ? (
                  <FilterSignalItem title="companySize" content={signal.filter.companySize?.join(', ')} />
                ) : null}
                {signal.filter?.country ? <FilterSignalItem title="region" content={signal.filter.country} /> : null}
                {signal.filter?.foundedFrom || signal.filter?.foundedTo ? (
                  <FilterSignalItem
                    title="foundingDate"
                    content={`${signal.filter.foundedFrom ?? 1900} - ${signal.filter.foundedTo ?? CURRENT_YEAR}`}
                  />
                ) : null}
                {signal.filter?.isFoundingInfoExist !== undefined ? (
                  <FilterSignalItem title="funding" content={signal.filter.isFoundingInfoExist} />
                ) : null}
                {signal.filter?.foundingStages && signal.filter.foundingStages.length > 0 ? (
                  <FilterSignalItem title="latestFunding" content={signal.filter.foundingStages?.join(', ')} />
                ) : null}
                {signal.filter?.tags ? (
                  <FilterSignalItem title="tags" content={signal.filter.tags?.join(', ')} />
                ) : null}
                {signal.filter?.entityTypeL2 ? (
                  <FilterSignalItem title="entityType" content={signal.filter.entityTypeL2} />
                ) : null}
                {signal.filter?.targetMarkets && signal.filter.targetMarkets.length > 0 ? (
                  <FilterSignalItem title="markets" content={signal.filter.targetMarkets?.join(', ')} />
                ) : null}
              </>
            )}
          </InfoCard.ContentItem>
        </SignalCardBody>
        <SignalCardFooter signal={signal} />
      </div>
    </InfoCard>
  );
};
