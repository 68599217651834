import { Typography } from '@components/Typography';
import { getStripePromise } from '@global/stripe';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Dispatch, FC, SetStateAction } from 'react';
import { CheckoutForm } from './CheckoutForm';

type PaymentProps = {
  options: StripeElementsOptions;
  onSuccess?: () => Promise<void>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
};

export const Payment: FC<PaymentProps> = ({ options, onSuccess, isProcessing, setIsProcessing }) => {
  const stripePromise = getStripePromise();

  return (
    <div className="flex flex-col gap-4">
      <Typography text="paySubscription" variant="lg/bold" className="capitalize text-grey-900" />
      {options && stripePromise ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm onSuccess={onSuccess} isProcessing={isProcessing} setIsProcessing={setIsProcessing} />
        </Elements>
      ) : null}
    </div>
  );
};
