import { SubscriptionPlanResponse } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { RadioCard } from '@global/components/RadioCards';
import { FC } from 'react';

type SubscriptionCardProps = {
  planInfo: SubscriptionPlanResponse;
  labelClassName?: string;
};

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  planInfo: { id, planName, price, monthlyCredits, isUnpaidPlan },
  labelClassName,
}) => {
  return (
    <RadioCard
      id={id}
      labelProps={{
        className: cn('w-1/2 min-w-[287px]', labelClassName),
      }}
      checked
      onChange={() => {}}
    >
      <div className="flex flex-col *:leading-[17px] *:text-black *:first-letter:capitalize">
        <div className="flex gap-0.5">
          <Typography arbitrary text={planName} variant="xs/bold" className="uppercase" />
          {isUnpaidPlan ? null : <Typography text={'monthly'} variant="xs/bold" />}
        </div>
        {isUnpaidPlan ? null : <Typography text={'billingMonthly'} variant="xs/regular" />}
      </div>
    </RadioCard>
  );
};
