import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';
import { LocalizedText, Typography } from '@components/Typography';
import { NegativeLayout } from '@global/components/NegativeLayout';
import { useMutationState } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';
import { BILLING_FEATURES_DATA } from '../constants';
import { BillingFeatureEnumType } from '../types';

type BillingFeatureGateProps = {
  icon: ReactNode;
  feature: BillingFeatureEnumType;
  title: LocalizedText;
  description: LocalizedText;
  unlockWarning: LocalizedText;
  find: LocalizedText;
  whereToFind: string;
  onUnlock: () => Promise<void>;
};

export const BillingFeatureGate: FC<BillingFeatureGateProps> = ({
  icon,
  feature,
  title,
  description,
  unlockWarning,
  find,
  whereToFind,
  onUnlock,
}) => {
  const data = BILLING_FEATURES_DATA.get(feature);

  const pendingMutations = useMutationState({
    filters: { status: 'pending' },
    select: (mutation) => mutation.state.status === 'pending',
  });

  return (
    <NegativeLayout className="min-h-[490px] flex-col">
      <div className="flex grow flex-col items-center justify-center">
        {icon}
        <Typography {...title} variant="doubleXl/bold" className="pt-5 text-grey-900 first-letter:capitalize" />
        <Typography {...description} variant="lg/regular" className="pb-6 pt-3 text-grey-900 first-letter:capitalize" />
        <Button
          onClick={async () => await onUnlock()}
          isLoading={pendingMutations.length > 0}
          intent={'accent'}
          className="h-12 w-60 items-center gap-2 px-10 py-[10px]"
        >
          <Typography
            text="unlockFeature"
            interpolationsParams={{ feature }}
            variant="sm/bold"
            className="text-white"
          />
          <div className="flex items-center rounded-full bg-white px-2">
            <SvgIcon name="Coins" className="size-7" />
            <Typography
              arbitrary
              text={data?.unlockCost.toString() ?? '1'}
              variant="sm/bold"
              className="text-primary-600"
            />
          </div>
        </Button>
      </div>
      <footer className="flex flex-col items-center justify-center">
        <Typography
          {...unlockWarning}
          variant="xs/regular"
          className="leading-[20px] text-grey-900 before:content-['*']"
        />
        <div className="flex gap-1">
          <Typography {...find} variant="xs/regular" className="leading-[20px] text-grey-900 first-letter:capitalize" />
          {whereToFind !== '' && (
            <Typography
              arbitrary
              text={whereToFind}
              variant="xs/regular"
              className={`leading-[20px] text-grey-900 underline first-letter:capitalize before:content-['"'] after:content-['"']`}
            />
          )}
        </div>
      </footer>
    </NegativeLayout>
  );
};
