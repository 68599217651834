import { useResetPassword } from '@/api/entities/accounts/useResetPassword';
import { useUserInfo } from '@/api/entities/accounts/useUserInfo';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { TileLayout } from '@global/components/TileLayout';
import { FC, useEffect, useRef } from 'react';

export const Password: FC = () => {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  const { userInfo, isUserInfoLoading } = useUserInfo({
    signal: abortController.current.signal,
  });

  const { resetPassword, isRestingPassword } = useResetPassword({
    signal: abortController.current.signal,
  });

  const handleResetPasswordClick = () => resetPassword({ ...userInfo, email: userInfo?.email as string, password: '' });

  return (
    <TileLayout className="grow pb-[25px] pt-6">
      <Typography tag="h3" text="password" variant="sm/bold" className="capitalize leading-[25px] text-grey-900" />
      <Button
        text="change password"
        disabled={isUserInfoLoading || isRestingPassword}
        isLoading={isRestingPassword}
        onClick={handleResetPasswordClick}
        className="w-[168px]"
      />
    </TileLayout>
  );
};
