import { Typography } from '@components/Typography';
import { FC } from 'react';

type TagProps = {
  value: string;
};

export const Tag: FC<TagProps> = ({ value }) => (
  <div className="rounded-full bg-grey-100 px-2 py-1">
    <Typography arbitrary text={value} variant="sm/bold" className="first-letter:capitalize text-grey-900" />
  </div>
);
