import { api } from '@/api/apiV2';
import { useInfiniteFeatureQuery } from '@/hooks/useInfiniteFeatureQuery';
import { useMemo } from 'react';
import { QueryKeys } from '../queryKeys';
import { PeopleEntity } from './types';

type UseTeamMembersArgs = {
  companyId: string;
  companyName: string | null | undefined;
  signal: AbortSignal;
};

export const useTeamMembers = ({ companyId, companyName, signal }: UseTeamMembersArgs) => {
  const { data, isFetching, ...rest } = useInfiniteFeatureQuery({
    queryKey: [QueryKeys.TeamMembers, companyId],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await api.teams.getCompanyTeamMembers(
        companyId,
        {
          Offset: pageParam,
        },
        {
          signal: signal,
        },
      );

      await new Promise((resolve) => setTimeout(resolve, 1000));

      return response.data;
    },
  });

  const teamMembers = useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.data)
        .map((people) => {
          if (!companyName) {
            return {
              ...people,
              id: people.peopleId,
            } satisfies PeopleEntity;
          }

          const joinedAt = people.experiences.reduce<number | undefined>((foundYear, experience) => {
            if (foundYear) return foundYear;

            const { subtitle, caption, title } = experience;

            const companyNameParts = companyName.split(/\s+/).filter((part) => part.length > 2);
            const isCompanyMatch = companyNameParts.some(
              (part) => subtitle.includes(part) || caption.includes(part) || title.includes(part),
            );

            if (!isCompanyMatch) {
              return undefined;
            }

            const yearMatch = subtitle.match(/\d{4}/) || caption.match(/\d{4}/);

            if (yearMatch) {
              return parseInt(yearMatch[0], 10);
            }

            const durationMatch = subtitle.match(/(\d+)\s*yr(?:s)?(?:\s+(\d+)\s*mo(?:s)?)?/i);

            if (durationMatch) {
              const years = parseInt(durationMatch[1], 10) || 0;
              const months = parseInt(durationMatch[2], 10) || 0;

              const totalMonths = years * 12 + months;
              const currentDate = new Date();

              const joinedDate = new Date(
                currentDate.getFullYear() - Math.floor(totalMonths / 12),
                currentDate.getMonth() - (totalMonths % 12),
              );

              return joinedDate.getFullYear();
            }

            return undefined;
          }, undefined);

          return {
            ...people,
            id: people.peopleId,
            joinedAt,
          } satisfies PeopleEntity;
        }),
    [data, companyName],
  );

  return {
    ...rest,
    isTeamMembersFetching: isFetching,
    teamMembers,
    isUnlocked: data?.pages[0].isUnlocked !== false,
    isEmpty: data?.pages.flatMap((page) => page.data).length === 0,
  };
};
