export const toEuro = (value: number) =>
  new Intl.NumberFormat('default', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);

export const getRenewalDate = (value: string | undefined) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  return date.toLocaleDateString(undefined, {
    dateStyle: 'long',
  });
};
