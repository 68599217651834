import { useBillingPlans, useUserSubscriptionState } from '@/api/entities/subscriptions';
import { useCancelSubscription } from '@/api/entities/subscriptions/useCancelSubscription';
import { useAbortController } from '@/hooks';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { BillingModal } from '@global/components/Billing/BillingModal';
import { Loadable } from '@global/components/Loadable';
import { TileLayout } from '@global/components/TileLayout';
import { FC, useMemo, useState } from 'react';
import { getRenewalDate, toEuro } from './helpers';
import { SubscriptionCard } from './parts/SubscriptionCard';

export const ActivePlanCard: FC = () => {
  const { signal } = useAbortController();

  const { subscriptionState, isUserSubscriptionStateLoading } = useUserSubscriptionState({ signal });
  const { billingPlans, isBillingPlansLoading } = useBillingPlans({ signal });

  const currentPlan = useMemo(
    () => billingPlans?.find((plan) => plan.id === subscriptionState?.id),
    [billingPlans, subscriptionState],
  );

  const { cancelSubscription, isCancellingSubscription } = useCancelSubscription();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TileLayout className="py-6 pe-[22px]">
        <Typography text={'activePlan'} variant="lg/bold" className="capitalize text-grey-900" />
        <Loadable
          isLoading={isUserSubscriptionStateLoading || isBillingPlansLoading || isCancellingSubscription}
          spinnerProps={{
            size: 'base',
          }}
        >
          {subscriptionState?.cancellationDate ? (
            <Typography
              text={'subscriptionCancellation'}
              interpolationsParams={{
                date: getRenewalDate(subscriptionState?.renewalDate),
              }}
              variant="sm/bold"
              className="text-grey-700 first-letter:capitalize"
            />
          ) : (
            <Typography
              text={'subscriptionInfo'}
              interpolationsParams={{
                amount: toEuro(currentPlan?.price ?? 0),
                date: getRenewalDate(subscriptionState?.renewalDate),
              }}
              variant="sm/bold"
              className="text-grey-700 first-letter:capitalize"
            />
          )}

          <div className="flex justify-between gap-4">
            {currentPlan ? <SubscriptionCard planInfo={currentPlan} /> : null}
            <Button text="changeSubscription" onClick={() => setIsModalOpen(true)} intent={'secondary'} />
          </div>
          {currentPlan?.isUnpaidPlan || subscriptionState?.cancellationDate ? null : (
            <button className="w-fit" onClick={async () => await cancelSubscription(currentPlan?.id)}>
              <Typography text="cancelSubscription" className="text-[10px] text-status-error first-letter:capitalize" />
            </button>
          )}
        </Loadable>
      </TileLayout>
      {isModalOpen ? <BillingModal onCloseHandler={() => setIsModalOpen(false)} /> : null}
    </>
  );
};
