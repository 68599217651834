import { useCompany } from '@/api/entities/companies';
import { useTeamMembers, useTeamMetrics } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { Loadable } from '@global/components/Loadable';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { NoTeamDataCard, TeamFeatureGate, TeamMembersList, TeamMetrics } from './parts';

export const TeamBoardMembersTab: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { company } = useCompany({
    companyId: id as string,
    signal,
  });

  const { isEmpty, isFetched, isUnlocked, isTeamMembersFetching } = useTeamMembers({
    companyId: id as string,
    companyName: company?.companyName,
    signal,
  });

  const { isTeamUnlocked, isFetching: isTeamFetching } = useTeamMetrics({
    companyId: id as string,
    signal,
  });

  return (
    <Loadable isLoading={isTeamMembersFetching || isTeamFetching}>
      {isUnlocked && isTeamUnlocked ? (
        <>
          {isFetched && isEmpty ? (
            <NoTeamDataCard />
          ) : (
            <div className="flex h-[70dvh] flex-col gap-[22px] overflow-y-auto pe-2 outline-none">
              <TeamMetrics />
              <TeamMembersList />
            </div>
          )}
        </>
      ) : (
        <>{company ? <TeamFeatureGate companyId={company?.companyId!} companyName={company?.companyName} /> : null}</>
      )}
    </Loadable>
  );
};
