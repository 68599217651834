import { cn } from '@/lib/utils';
import { BaseCheckbox } from '@components/Checkbox';
import { FC } from 'react';
import { RadioCardProps } from './types';

export const RadioCard: FC<RadioCardProps> = ({
  id,
  checked,
  onChange,
  labelProps,
  children,
  wrapperClassName,
  className,
  disabled,
  ...checkboxProps
}) => (
  <label
    {...labelProps}
    htmlFor={id}
    data-clickable={!!onChange}
    aria-disabled={disabled}
    aria-selected={checked}
    className={cn(
      'group/radio flex items-center justify-between bg-grey-100 px-4 py-[11px] drop-shadow aria-selected:bg-grey-300 data-[clickable=true]:cursor-pointer hover:drop-shadow-lg motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none',
      labelProps?.className,
    )}
  >
    {children}
    {onChange ? (
      <BaseCheckbox
        id={id}
        {...checkboxProps}
        checked={checked}
        onChange={disabled ? undefined : (e) => onChange(id, e)}
        wrapperClassName={cn('rounded-full', wrapperClassName)}
        className={cn('rounded-full group-aria-disabled/radio:hidden', className)}
      />
    ) : null}
  </label>
);
