import { BillingFeatureData, BillingFeatureEnumType } from './types';

export const BILLING_FEATURES_DATA = new Map<BillingFeatureEnumType, BillingFeatureData>([
  [
    'Team',
    {
      icon: 'FeatureCoins',
      unlockCost: 1,
    },
  ],
  [
    'Financials',
    {
      icon: 'Financials',
      unlockCost: 1,
    },
  ],
]);
