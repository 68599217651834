import { PaymentMethodResponse } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { FC } from 'react';
import { RadioCard, SelectableRadioCardProps } from '../parts';
import { formatCreditCardDate } from './helpers';

type PaymentCardProps = {
  cardInfo: PaymentMethodResponse;
  labelClassName?: string;
} & Omit<SelectableRadioCardProps, 'children' | 'labelProps' | 'inputProps' | 'id'>;

export const PaymentCard: FC<PaymentCardProps> = ({
  cardInfo: { id, last4Digits, expirationDate, brand },
  labelClassName,
  ...rest
}) => (
  <RadioCard
    {...rest}
    id={id}
    labelProps={{
      className: cn('w-1/2 min-w-[287px]', labelClassName),
    }}
  >
    <div className="flex flex-col *:leading-[17px] *:text-black *:first-letter:capitalize">
      <Typography text={'cardEndingIn'} interpolationsParams={{ brand, last4Digits }} variant="xs/bold" />
      <Typography
        text={'expireIn'}
        interpolationsParams={{ date: formatCreditCardDate(expirationDate) }}
        variant="xs/regular"
      />
    </div>
  </RadioCard>
);
