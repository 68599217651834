import { InfoCard } from '@global/components/InfoCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyFilter } from './types';

type FilterSignalItemProps = {
  title: CompanyFilter;
  content: string | boolean | null | undefined;
};

export const FilterSignalItem: FC<FilterSignalItemProps> = ({ title, content }) => {
  const { t } = useTranslation();

  return <InfoCard.ContentItemContent text={`${t(title)} - ${content}`} className="line-clamp-1" />;
};
