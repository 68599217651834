import { useUserInfo } from '@/api/entities/accounts/useUserInfo';
import { useAttachPaymentMethod } from '@/api/entities/paymentMethods';
import { Button } from '@components/Button';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { FC, FormEventHandler, useState } from 'react';

type AddPaymentMethodModalContentProps = {
  clientSecret: string;
  onSubmit: VoidFunction;
};

export const AddPaymentMethodModalContent: FC<AddPaymentMethodModalContentProps> = ({ clientSecret, onSubmit }) => {
  const { userInfo } = useUserInfo({});
  const { attachPaymentMethod } = useAttachPaymentMethod({ onSubmit });
  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement('card') as StripeCardElement,
        billing_details: {
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          email: userInfo?.email,
        },
      },
    });

    console.log(setupIntent);

    if (error) {
      console.error('Error confirming Setup Intent:', error.message);
    } else {
      await attachPaymentMethod(setupIntent.payment_method as string);
    }

    setIsProcessing(false);
  };

  return (
    <form id="create-payment-method-form" onSubmit={handleSubmit} className="flex h-28 flex-col justify-between">
      <CardElement
        options={{
          hidePostalCode: true,
        }}
      />
      <Button text="addCard" type="submit" size={'large'} isLoading={isProcessing} className="w-1/2 self-center" />
    </form>
  );
};
