import { QueryKeys } from '@/api/entities/queryKeys';
import { useBillingPlans, useSubscribeToPlan, useUserSubscriptionState } from '@/api/entities/subscriptions';
import { SubscriptionPlanResponse } from '@/api/generated/Api';
import { toEuro } from '@/pages/Profile/parts/Billing/parts/ActivePlanCard/helpers';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { BillingPlanRadioCard } from '@global/components/RadioCards';
import { Payment } from '@global/components/Stripe/Payment';
import { useQueryClient } from '@tanstack/react-query';
import { delay } from '@utils';
import { MoveRight } from 'lucide-react';
import { ComponentProps, FC, FormEventHandler, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { BillingModalNotification, BillingPlanItem } from './parts';

type BillingModalProps = {
  onCloseHandler?: VoidFunction;
  headerProps?: ComponentProps<typeof BillingModalNotification>;
  showUpgradeOnly?: boolean;
  renderTo?: string;
};

export const BillingModal: FC<BillingModalProps> = ({
  onCloseHandler,
  headerProps,
  showUpgradeOnly,
  renderTo = 'global-modals-container',
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanResponse | null>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { subscriptionState } = useUserSubscriptionState();
  const { billingPlans, isBillingPlansLoading } = useBillingPlans();

  useEffect(() => {
    setSelectedPlan(billingPlans?.find((plan) => plan.id === subscriptionState?.id)!);
  }, [billingPlans, subscriptionState]);

  const handleChangePlan = async (id: string) => {
    const plan = billingPlans?.find((plan) => plan.id === id)!;

    setSelectedPlan(plan);
  };

  const { data, subscribeToPlan, isLoading, reset } = useSubscribeToPlan();

  const onSuccess = async () => {
    await delay(3000);

    await queryClient.invalidateQueries({
      queryKey: [QueryKeys.UserSubscriptionState],
    });

    await queryClient.invalidateQueries({
      queryKey: [QueryKeys.PaymentMethods],
    });

    setIsProcessing(false);

    navigate('/profile?tab=Plan%26Billing');

    onCloseHandler?.();
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (selectedPlan) {
      const response = await subscribeToPlan(selectedPlan?.id);

      if (!response.clientSecret) {
        await onSuccess();
      }
    } else {
      onCloseHandler?.();
    }
  };

  useEffect(() => {
    reset();
  }, [selectedPlan]);

  const availablePlans = useMemo(() => {
    if (!showUpgradeOnly) {
      return billingPlans;
    }

    const currentPlan = billingPlans?.find((plan) => plan.id === subscriptionState?.id)!;

    return billingPlans?.filter((plan) => plan.price >= currentPlan.price);
  }, [billingPlans, showUpgradeOnly]);

  const layout = (
    <div
      data-isopen={true}
      className="fixed inset-0 hidden bg-black-500/50 outline-0 data-[isopen=true]:z-[9999] data-[isopen=true]:flex data-[isopen=true]:items-center data-[isopen=true]:justify-center"
    >
      <div className="relative flex gap-5 rounded-lg bg-base-200 p-[30px] pt-5">
        <form onSubmit={handleSubmit} className="flex min-w-60 max-w-[800px] flex-col items-center gap-3 bg-base-200">
          {onCloseHandler && !isProcessing && !isLoading ? (
            <button onClick={onCloseHandler} className="absolute right-5 top-5">
              <IoClose size={26} />
            </button>
          ) : null}
          {headerProps ? <BillingModalNotification {...headerProps} /> : null}
          <div className="flex min-h-96 min-w-96 flex-col items-center">
            <div className="flex flex-col items-end gap-1 pb-5">
              <img src="/logo.png" alt="logo" className="w-[202px]" />
              {selectedPlan && !selectedPlan.isUnpaidPlan ? (
                <div className="me-1 w-fit rounded-lg bg-custom-gradient px-1.5 py-0.5">
                  <Typography
                    arbitrary
                    text={selectedPlan.planName}
                    variant="xs/bold"
                    className="uppercase text-white"
                  />
                </div>
              ) : null}
            </div>
            <Loadable isLoading={isBillingPlansLoading}>
              <ul className="pb-10">
                <BillingPlanItem text="unlimitedQueries" />
                {selectedPlan ? (
                  <BillingPlanItem
                    text="creditsPerMonth"
                    interpolationsParams={{ credits: selectedPlan.monthlyCredits, feature: 'team' }}
                  />
                ) : null}
              </ul>
              <ul className="flex w-full flex-row justify-center gap-x-[21px]">
                {availablePlans?.map((plan) => (
                  <BillingPlanRadioCard
                    key={plan.id}
                    id={plan.id}
                    checked={selectedPlan === plan}
                    onChange={subscriptionState?.id === plan.id ? undefined : handleChangePlan}
                  >
                    <Typography arbitrary text={plan.planName} variant="lg/bold" className="leading-[17px]" />
                    <Typography
                      arbitrary
                      text={toEuro(plan.price)}
                      className="text-[1.75rem] font-bold leading-[35.1px]"
                    />
                    {plan.isUnpaidPlan ? null : (
                      <Typography text={`billed_monthly`} className="text-[10px] leading-[17px]" />
                    )}
                  </BillingPlanRadioCard>
                ))}
              </ul>
              <Button
                type="submit"
                isLoading={isLoading || isProcessing}
                disabled={selectedPlan?.id === subscriptionState?.id || isProcessing || !!data?.clientSecret}
                intent={'shadow'}
                className="my-7 h-12 w-1/2 items-center gap-2 rounded-lg bg-primary-600 normal-case"
              >
                <Typography
                  text={selectedPlan?.isUnpaidPlan ? 'useFreeAccount' : 'subscribe'}
                  variant="xs/regular"
                  className="text-white first-letter:capitalize"
                />
                <MoveRight />
              </Button>
              {/* <Typography
                text={'subscriptionWarning'}
                interpolationsParams={{ amount: 130 }}
                className="self-start text-[8px] text-grey-700"
              /> */}
            </Loadable>
          </div>
        </form>
        {data?.clientSecret ? (
          <Payment
            options={{
              clientSecret: data.clientSecret,
            }}
            onSuccess={onSuccess}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
          />
        ) : null}
      </div>
    </div>
  );

  const container = document.getElementById(renderTo);
  return container ? createPortal(layout, container) : layout;
};
