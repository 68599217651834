import { api } from '@/api/apiV2';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UsePaymentMethodsArgs = {
  signal?: AbortSignal;
};

export const usePaymentMethods = ({ signal }: UsePaymentMethodsArgs) => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QueryKeys.PaymentMethods],
    queryFn: async () => {
      var response = await api.paymentMethods.getPaymentMethods({ signal });

      return response.data;
    },
  });

  return {
    paymentMethods: data,
    isPaymentMethodsLoading: isLoading,
    refetchPaymentMethods: refetch,
    isFetching,
  };
};
