import { CURRENT_YEAR } from '@global/constants/shared';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Select from 'react-select';
import {
  COMPANY_TYPE_OPTIONS,
  companySizeOptions,
  latestFundingTypeOptions,
  regionOptions,
  TAGS_OPTIONS,
  TARGET_MARKETS_OPTIONS,
} from '../data/options';
import { MultiSelect } from './MultiSelect';
import { NestedSelect } from './NestedSelect';

function foundingText(value) {
  return `${value}°C`;
}

const minDistance = 0;

const Filters = ({ setFormData, formData, isHome = false }) => {
  const handleChangeDropdown = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };

  const handleChangeToggle = (e) => {
    const { checked, dataset } = e.target;
    const label = dataset.label;
    setFormData((prevData) => ({
      ...prevData,
      [label]: checked,
    }));
  };

  const handleFoundingDateFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFormData((prevState) => ({ ...prevState, foundedFrom: newValue[0], foundedFromChanged: true }));
    } else {
      setFormData((prevState) => ({ ...prevState, foundedTo: newValue[1], foundedToChanged: true }));
    }
  };

  const handleRelevantScoreFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setFormData((prevState) => ({ ...prevState, relevantScore: [clamped, 100] }));
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setFormData((prevState) => ({ ...prevState, relevantScore: [clamped - minDistance, 100] }));
      }
    } else {
      setFormData((prevState) => ({ ...prevState, relevantScore: [newValue[0], 100] }));
    }
  };

  const handleChangeCompanySize = (item) => {
    setFormData((prevState) => {
      const newSelectedItems = [...prevState.companySize];

      if (newSelectedItems.includes(item)) {
        const index = newSelectedItems.indexOf(item);
        newSelectedItems.splice(index, 1);
      } else {
        newSelectedItems.push(item);
      }

      return {
        ...prevState,
        companySize: newSelectedItems,
      };
    });
  };

  const handleChangeFundingStages = (item) => {
    setFormData((prevState) => {
      const newSelectedItems = [...prevState.fundingStages];

      if (newSelectedItems.includes(item)) {
        const index = newSelectedItems.indexOf(item);
        newSelectedItems.splice(index, 1);
      } else {
        newSelectedItems.push(item);
      }

      if (newSelectedItems.length > 0) {
        return {
          ...prevState,
          funding: true,
          fundingStages: newSelectedItems,
        };
      } else {
        return {
          ...prevState,
          funding: false,
          fundingStages: newSelectedItems,
        };
      }
    });
  };

  const handleChangeMultiSelectField = (item, field) => {
    setFormData((prevState) => {
      const newSelectedItems = [...prevState[field]];

      if (newSelectedItems.includes(item)) {
        const index = newSelectedItems.indexOf(item);
        newSelectedItems.splice(index, 1);
      } else {
        newSelectedItems.push(item);
      }

      return {
        ...prevState,
        [field]: newSelectedItems,
      };
    });
  };

  const handleChangeTags = (item) => handleChangeMultiSelectField(item, 'tags');
  const handleChangeTargetMarkets = (item) => handleChangeMultiSelectField(item, 'targetMarkets');

  return (
    <div className="justify-left flex items-start gap-5">
      {/* <div className="text-2xl font-semibold mt-8">Filters:</div> */}
      <div
        className={
          isHome
            ? 'grid w-full grid-cols-2 items-center gap-5 text-sm lg:grid-cols-4'
            : `justify-left flex min-h-[120px] flex-grow items-start gap-5 overflow-x-auto overflow-y-hidden pl-10`
        }
      >
        {/* Relevant Score */}
        <div
          className={`${!isHome && `min-w-[200px]`} flex flex-col justify-center rounded-lg bg-base-200 p-3 shadow-md`}
        >
          <span className="block text-sm font-bold">Minimum Score</span>
          <Box>
            <Slider
              getAriaLabel={() => 'Relevant Score slider'}
              value={formData.relevantScore}
              onChange={handleRelevantScoreFilter}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disableSwap
              step={10}
              sx={{
                '& .MuiSlider-thumb': {
                  height: 16,
                  width: 16,
                  backgroundColor: '##7779FF',
                },
                '& .MuiSlider-thumb:last-of-type': {
                  display: 'none', // Hides the right thumb
                },
              }}
            />
          </Box>
          {/* range */}
          <div className="flex items-center justify-between text-[12px] dark:text-slate-600">
            <span>{formData.relevantScore[0]}</span>
            <span>{formData.relevantScore[1]}</span>
          </div>
        </div>

        {/* CompanySize */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } flex-cols justify-top flex h-[98px] flex-col gap-2 rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <div className="block text-sm font-bold">Company Size</div>
          <div>
            <MultiSelect
              options={companySizeOptions}
              selectedItems={formData.companySize}
              handleToggleItem={handleChangeCompanySize}
            />
          </div>
        </div>

        {/* Region */}
        <div
          className={`${!isHome && `min-w-[200px]`} flex flex-col justify-center rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <span className="dark: block text-sm font-bold">Region</span>
          <Select
            options={regionOptions}
            name="selectedRegion"
            value={formData.selectedRegion}
            className="mt-2 bg-base-200"
            styles={{
              control: (provided) => ({
                ...provided,
                fontSize: '10px',
                borderRadius: '0.375rem',
              }),
              dropdownIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
              }),
              menuList: (base) => ({
                ...base,
                fontSize: '10px',
              }),
            }}
            onChange={handleChangeDropdown}
            menuPortalTarget={document.body}
          />
        </div>

        {/* Founding Date */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } mr-2 flex flex-col justify-center rounded-lg bg-base-200 p-3 shadow-md`}
        >
          <span className="block text-sm font-bold">Founding Date</span>
          <Box className="w-full">
            <Slider
              getAriaLabel={() => 'Founding Date slider'}
              value={[formData.foundedFrom, formData.foundedTo]}
              onChange={handleFoundingDateFilter}
              valueLabelDisplay="auto"
              min={1900}
              max={CURRENT_YEAR}
              getAriaValueText={foundingText}
              disableSwap
            />
          </Box>
          {/* Range */}
          <div className="flex items-center justify-between text-[12px] dark:text-slate-600">
            <span>{formData.foundeFrom || 1900}</span>
            <span>{formData.foundedTo || 2024}</span>
          </div>
        </div>

        {/* Startup - COMMENTED because no Startup innovative, re-activate when filters is improved */}
        {/* <div className={`bg-base-200 flex flex-col justify-center rounded-lg shadow-md p-4`}>
          <span className="block font-bold  text-sm a">Startup?</span>
          <div className="flex items-center justify-left gap-2">
            <span className=" text-sm">No</span>
            <div className="form-control">
              <label className="cursor-pointer label flex gap-4 w-fit">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={formData.startup}
                  onChange={handleChangeToggle}
                  data-label="startup"
                />
              </label>
            </div>
            <span className=" text-sm">Yes</span>
          </div>
        </div> */}

        {/* Funding */}
        <div className={`flex flex-col justify-center rounded-lg bg-base-200 p-4 shadow-md`}>
          <span className="a block text-sm font-bold">Funding?</span>
          <div className="justify-left flex items-center gap-2">
            <span className="text-sm">No</span>
            <div className="form-control">
              <label className="label flex w-fit cursor-pointer gap-4">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={formData.funding}
                  onChange={handleChangeToggle}
                  data-label="funding"
                />
              </label>
            </div>
            <span className="text-sm">Yes</span>
          </div>
        </div>

        {/* Funding Stages */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } flex-cols justify-top flex h-[98px] flex-col gap-2 rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <div className="block text-sm font-bold">Latest Funding</div>
          <div>
            <MultiSelect
              options={latestFundingTypeOptions}
              selectedItems={formData.fundingStages}
              handleToggleItem={handleChangeFundingStages}
            />
          </div>
        </div>

        {/* Tags */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } flex-cols justify-top flex h-[98px] flex-col gap-2 rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <div className="block text-sm font-bold">Tags</div>
          <div>
            <MultiSelect
              options={TAGS_OPTIONS}
              selectedItems={formData.tags}
              handleToggleItem={handleChangeTags}
              isSearchable
            />
          </div>
        </div>

        {/*Entity type */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } flex-cols justify-top flex h-[98px] flex-col gap-2 rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <div className="block text-sm font-bold">Entity Type</div>
          <div>
            <NestedSelect
              options={COMPANY_TYPE_OPTIONS}
              selectedItems={formData.companyType}
              handleChange={(value) => handleChangeDropdown(value, { name: 'companyType' })}
            />
          </div>
        </div>

        {/* Target Markets */}
        <div
          className={`${
            !isHome && `min-w-[200px]`
          } flex-cols justify-top flex h-[98px] flex-col gap-2 rounded-lg bg-base-200 p-4 shadow-md`}
        >
          <div className="block text-sm font-bold">Markets</div>
          <div>
            <MultiSelect
              options={TARGET_MARKETS_OPTIONS}
              selectedItems={formData.targetMarkets}
              handleToggleItem={handleChangeTargetMarkets}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
