import { usePaymentMethods, useSetDefaultPaymentMethod } from '@/api/entities/paymentMethods';
import { useUserSubscriptionState } from '@/api/entities/subscriptions';
import { useAbortController } from '@/hooks';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { PaymentCard } from '@global/components/RadioCards/PaymentCard/PaymentCard';
import { TileLayout } from '@global/components/TileLayout';
import { useMutationState } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { AddPaymentMethodModal, DetachPaymentMethodButton } from './parts';

export const PaymentMethodCard: FC = () => {
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] = useState(false);

  const { signal } = useAbortController();
  const { paymentMethods, isPaymentMethodsLoading, isFetching } = usePaymentMethods({ signal });

  const { setAsDefault } = useSetDefaultPaymentMethod();

  const pendingMutations = useMutationState({
    filters: { status: 'pending' },
    select: (mutation) => mutation.state.status === 'pending',
  });

  const { subscriptionState, isUserSubscriptionStateLoading } = useUserSubscriptionState({ signal });

  return (
    <>
      <TileLayout className="gap-4py-6 pe-[22px]">
        <header className="flex justify-between">
          <div className="flex flex-col">
            <Typography text={'paymentMethod'} variant="lg/bold" className="capitalize text-grey-900" />
            <Typography
              text={'paymentMethodDescription'}
              variant="sm/regular"
              className="text-grey-900 first-letter:capitalize"
            />
          </div>
          <Button onClick={() => setIsAddPaymentMethodModalOpen(true)} text={'add'} className="w-[114px]" />
        </header>
        <ul
          aria-disabled={isFetching || pendingMutations.length > 0}
          className="flex flex-col items-center gap-2 aria-disabled:pointer-events-none aria-disabled:opacity-50"
        >
          <Loadable
            isLoading={isPaymentMethodsLoading || isUserSubscriptionStateLoading}
            spinnerProps={{
              size: 'base',
            }}
          >
            {paymentMethods?.length ? (
              paymentMethods?.map((cardInfo) => (
                <li key={cardInfo.id} className="flex w-full items-center justify-between gap-5">
                  <PaymentCard
                    cardInfo={cardInfo}
                    checked={cardInfo.isDefault}
                    disabled={cardInfo.isDefault}
                    onChange={async (id) => await setAsDefault(id)}
                    labelClassName="w-full"
                  />
                  <DetachPaymentMethodButton
                    id={cardInfo.id}
                    disabled={subscriptionState?.isUnpaid ? undefined : paymentMethods.length <= 1}
                  />
                </li>
              ))
            ) : (
              <li className="self-start pt-5">
                <Typography text="noPaymentMethods" variant="sm/bold" className="text-error" />
              </li>
            )}
          </Loadable>
        </ul>
      </TileLayout>
      {isAddPaymentMethodModalOpen ? (
        <AddPaymentMethodModal onCloseHandler={() => setIsAddPaymentMethodModalOpen(false)} />
      ) : null}
    </>
  );
};
