import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { ComponentProps, ComponentPropsWithoutRef, FC } from 'react';

type BaseCheckboxProps = {
  wrapperClassName?: string;
  checkProps?: Omit<ComponentProps<typeof SvgIcon>, 'name'>;
} & Omit<ComponentPropsWithoutRef<'input'>, 'type'>;

export const BaseCheckbox: FC<BaseCheckboxProps> = ({ wrapperClassName, checkProps, ...inputProps }) => (
  <div
    className={cn(
      'relative flex size-[18px] rounded-md bg-gradient-to-r from-gradient-from to-gradient-to p-[2px] has-[:checked]:p-[9px] hover:p-[9px] group-hover/radio:p-[9px] motion-safe:transition-all motion-safe:duration-300 motion-reduce:transition-none',
      wrapperClassName,
    )}
  >
    <input
      {...inputProps}
      type="checkbox"
      className={cn('peer size-full appearance-none rounded-[4px] bg-white', inputProps?.className)}
    />
    <SvgIcon
      {...checkProps}
      name="Checkmark"
      className={cn(
        'absolute left-0 top-0 opacity-0 motion-safe:translate-y-full motion-safe:transition-all motion-safe:duration-200 motion-safe:peer-checked:animate-check motion-reduce:peer-checked:opacity-100',
        checkProps?.className,
      )}
    />
  </div>
);
