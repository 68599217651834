import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../queryKeys';

type useUnlockTeamArgs = {
  onSuccess?: (companyId: string) => void;
};

export const useUnlockTeam = (args?: useUnlockTeamArgs) => {
  const { onSuccess } = args || {};
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [MutationKeys.UnlockTeamFeature],
    mutationFn: async (companyId: string) => {
      try {
        await api.teams.unlockTeam(companyId);

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserSubscriptionState],
        });

        queryClient.resetQueries({
          queryKey: [QueryKeys.TeamMembers, companyId],
        });

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TeamMetrics, companyId],
        });

        onSuccess?.(companyId);
      } catch (error: any) {
        if (error.response?.status === 400) {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.UserSubscriptionState],
          });
        }
      }
    },
  });

  return {
    unlockTeam: mutateAsync,
    isTeamUnlocking: isPending,
  };
};
