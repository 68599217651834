import { api } from '@/api/apiV2';
import { SubscriptionPlanResponse } from '@/api/generated/Api';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseBillingPlansArgs = {
  onSuccess?: (data: SubscriptionPlanResponse[]) => void;
  signal?: AbortSignal;
};

export const useBillingPlans = (args?: UseBillingPlansArgs) => {
  const { onSuccess, signal } = args || {};

  const { data, isFetching, ...rest } = useQuery({
    queryKey: [QueryKeys.BillingPlans],
    queryFn: async () => {
      var response = await api.subscription.getDefaultPlans();

      onSuccess?.(response.data);

      return response.data;
    },
  });

  return {
    billingPlans: data,
    isBillingPlansLoading: isFetching,
    ...rest,
  };
};
