import { api } from '@/api/apiV2';
import { useMutation } from '@tanstack/react-query';
import { MutationKeys } from '../queryKeys';

export const useSubscribeToPlan = () => {
  const { data, isPending, mutateAsync, ...rest } = useMutation({
    mutationKey: [MutationKeys.SubscribeToPlan],
    mutationFn: async (subscriptionId: string) => {
      const response = await api.subscription.subscribeToPlan(subscriptionId);

      return response.data;
    },
  });

  return {
    data,
    isLoading: isPending,
    subscribeToPlan: mutateAsync,
    ...rest,
  };
};
