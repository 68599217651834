import { useCompany } from '@/api/entities/companies';
import { useTeamMembers } from '@/api/entities/teams';
import { useAbortController } from '@/hooks';
import { Spinner } from '@components/Spinner';
import { Loadable } from '@global/components/Loadable';
import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { TeamMemberCard } from './parts';

export const TeamMembersList: FC = () => {
  const { id } = useParams();
  const { signal } = useAbortController();

  const { company } = useCompany({
    companyId: id as string,
    signal,
  });

  const { teamMembers, isTeamMembersFetching, isLoading, isFetchingNextPage, isEmpty, fetchNextPage, hasNextPage } =
    useTeamMembers({
      companyId: id as string,
      companyName: company?.companyName,
      signal,
    });

  const { ref, inView } = useInView({
    threshold: 0.85,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <div className="flex h-full flex-col gap-3">
      <Loadable isLoading={isLoading}>
        {isEmpty ? (
          <>Not found</>
        ) : (
          <ul className="grid grid-cols-[repeat(auto-fit,minmax(366px,max-content))] gap-[21px]">
            {teamMembers?.map((member) => <TeamMemberCard key={member.peopleId} data={member} />)}
          </ul>
        )}
      </Loadable>
      {(hasNextPage ?? !isTeamMembersFetching) ? (
        <div ref={ref} className="flex h-14 shrink-0 justify-center pt-3">
          {isFetchingNextPage ? <Spinner size="base" /> : null}
        </div>
      ) : null}
    </div>
  );
};
