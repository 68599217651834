import { cva } from 'class-variance-authority';

export const styles = cva('flex justify-center items-center gap-1 *:text-black size-fit py-1 px-5 rounded-md', {
  variants: {
    intent: {
      warning: ['bg-status-warning'],
    },
  },
  compoundVariants: [
    {
      intent: 'warning',
    },
  ],
  defaultVariants: {
    intent: 'warning',
  },
});
