import { useUnlockTeam } from '@/api/entities/teams/useUnlockTeam';
import { SvgIcon } from '@components/SvgIcon';
import { BillingFeatureGate } from '@global/components/Billing';
import { FC } from 'react';

type TeamFeatureGateProps = {
  companyId: string;
  companyName: string | null | undefined;
};

export const TeamFeatureGate: FC<TeamFeatureGateProps> = ({ companyId, companyName }) => {
  const { unlockTeam } = useUnlockTeam();

  return (
    <BillingFeatureGate
      feature="Team"
      onUnlock={async () => await unlockTeam(companyId)}
      icon={<SvgIcon name="PieChart" className="size-14" />}
      title={{
        text: 'team_feature_title',
        interpolationsParams: { companyName: companyName ?? '-' },
      }}
      description={{
        text: 'team_feature_description',
      }}
      unlockWarning={{
        text: 'team_feature_unlockWarning',
      }}
      find={{
        text: 'team_feature_find',
      }}
      whereToFind="My Lists"
    />
  );
};
