import { api } from '@/api/apiV2';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

export const useCreatePaymentMethodSetupIntent = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [QueryKeys.CreatePaymentMethodSetupIntent],
    queryFn: async () => {
      var response = await api.paymentMethods.createSetupIntent();

      return response.data;
    },
    staleTime: 0,
  });

  return {
    data,
    isLoading: isFetching,
    refetch,
  };
};
