import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { NestedSelectOption } from './types';

type NestedSelectProps = {
  options: NestedSelectOption[];
  selectedOption: NestedSelectOption;
  handleChange: (option: SingleValue<NestedSelectOption>) => void;
};

export const NestedSelect: FC<NestedSelectProps> = ({ options, handleChange, selectedOption }) => {
  const { t } = useTranslation();

  return (
    <Select
      name="options"
      options={options}
      value={selectedOption}
      className="rounded-lg border bg-base-200"
      isSearchable={false}
      placeholder={t('select')}
      isClearable
      escapeClearsValue
      backspaceRemovesValue
      getOptionLabel={(option) => t(option.label)}
      styles={{
        control: (provided) => ({
          ...provided,
          fontSize: '10px',
          borderRadius: '0.375rem',
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menuList: (base) => ({
          ...base,
          fontSize: '10px',
        }),
        placeholder: (base) => ({
          ...base,
          textTransform: 'capitalize',
        }),
      }}
      onChange={handleChange}
      menuPortalTarget={document.body}
    />
  );
};
