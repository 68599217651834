import { SignalResponse } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { useSignalsPageActions } from '@/pages/Signals/context';
import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { FC, PropsWithChildren } from 'react';
import { SignalActions } from './parts/SignalActions/SignalActions';
import { SignalTypeTag } from './parts/SignalTypeTag/SignalTypeTag';

type SignalCardHeaderProps = {
  signal: SignalResponse;
  className?: string;
} & PropsWithChildren;

export const SignalCardHeader: FC<SignalCardHeaderProps> = ({ signal, className, children }) => {
  const { openAddToListPopup } = useSignalsPageActions();

  const handleAddToList = () => {
    if (signal.company.companyId) {
      openAddToListPopup(signal.company.companyId);
    }
  };

  return (
    <header className={cn('flex flex-col gap-1', className)}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5">
          <SignalTypeTag type={signal.signalType} />
          {children}
        </div>
        <div className="flex items-center gap-0.5 py-0.5">
          {signal.company.companyId ? (
            <Button onClick={handleAddToList} intent={'accent'} className="flex items-center gap-1.5 px-2 py-1">
              <SvgIcon name="Plus" />
              <Typography text="add" variant="sm/regular" className="capitalize leading-4 text-base-50" />
            </Button>
          ) : null}
          <SignalActions signal={signal} />
        </div>
      </div>
      {signal.company.listsCount > 0 ? (
        <div className="self-end px-3">
          <Typography
            text="presentInXLists"
            interpolationsParams={{ count: signal.company.listsCount }}
            variant="xs/regular"
            className="text-grey-700 first-letter:capitalize"
          />
        </div>
      ) : null}
    </header>
  );
};
