import { useChatMessages, useSendMessage } from '@/api/entities/chat/useChat';
import { Message } from '@/api/entities/chat/types';
import { Button } from '@/components/Button';
import { useState, useEffect, useRef } from 'react';
import { ChatMessageList, ChatBubble, ChatBubbleAvatar, ChatBubbleMessage } from '@/components/ui/chat';
import { ChatInput } from '@/components/ui/chat/chat-input';
import { Send } from 'lucide-react';
import { IconName, SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { TranslationKeys } from '@/i18n';
import { useTranslation } from 'react-i18next';
import { PiUsersThreeBold } from 'react-icons/pi';
import { GrMoney } from 'react-icons/gr';
import { TbTargetArrow } from 'react-icons/tb';

interface ChatComponentProps {
  companyId: string;
  threadId: string;
}

type DefaultMessage = {
  id: string;
  icon: React.ElementType;
  text: TranslationKeys;
  color: string;
};

const defaultMessages: DefaultMessage[] = [
  {
    id: '1',
    icon: PiUsersThreeBold,
    text: 'defaultQuestion1',
    color: 'text-primary-400',
  },
  {
    id: '2',
    icon: TbTargetArrow,
    text: 'defaultQuestion2',
    color: 'text-primary-300',
  },
  {
    id: '3',
    icon: GrMoney,
    text: 'defaultQuestion3',
    color: 'text-primary-500',
  },
];

const ChatComponent: React.FC<ChatComponentProps> = ({ companyId, threadId }) => {
  useEffect(() => {}, []);
  const { t } = useTranslation();

  const { data: messages, isLoading } = useChatMessages(threadId);
  const sendMessageMutation = useSendMessage();
  const [input, setInput] = useState('');
  const [latestInput, setLatestInput] = useState('');

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.type === 'ai') {
        setInput('');
      }
    }
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (message: string) => {
    sendMessageMutation.mutate({ threadId, companyId, question: message });
    scrollToBottom();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.trim()) {
      handleSendMessage(input);
    }
  };

  const onSend = () => {
    if (input.trim()) {
      if (sendMessageMutation.isPending || isLoading || !input) return;
      setLatestInput(input);
      setInput('');
      scrollToBottom();
      handleSendMessage(input);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (sendMessageMutation.isPending || isLoading || !input) return;
      onSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
      setLatestInput(input);
      setInput('');
      scrollToBottom();
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem('thread_id', threadId);
  }, [threadId]);

  const handleBubbleClick = (content: string) => {
    const translatedText = t(content, { ...{} });
    handleSendMessage(translatedText);
    setLatestInput(translatedText);
    scrollToBottom();
  };

  useEffect(() => {}, [messages]);

  return (
    <div className="relative flex h-full w-full flex-col">
      <div className="relative flex-1 overflow-hidden">
        <ChatMessageList className="h-full overflow-y-auto pb-32" ref={messagesContainerRef}>
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <span className="loading loading-dots loading-md"></span>
            </div>
          ) : (
            <>
              {messages?.length === 0 && latestInput === '' && (
                <div className="flex h-full w-full flex-col items-center justify-center gap-10">
                  <SvgIcon name={'Assistante'} className="h-12 w-12 rounded-full border-2 border-grey-100 p-2" />
                  {/* <img src="/logo.png" alt="logo" className="w-[150px]" /> */}
                  {/* <SvgIcon name={'Logo'} className="h-32 w-32" /> */}
                  {/* <img src="/logo-no-text.png" alt="logo" className="w-[70px]" /> */}

                  <div className="grid max-w-[800px] grid-cols-3 gap-5">
                    {defaultMessages.map(({ id, icon: Icon, text, color }) => (
                      <Button
                        key={id}
                        onClick={() => handleBubbleClick(text)}
                        intent="empty"
                        className="flex h-full w-full flex-col items-start justify-start rounded-xl border-2 border-grey-100 p-4 transition-all hover:bg-grey-100"
                      >
                        <Icon className={`h-5 w-5 ${color}`} />
                        <Typography text={text as TranslationKeys} className="mt-2 text-left text-sm normal-case" />
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              {messages?.map((msg: Message, idx: number) => (
                <ChatBubble key={msg.id} variant={msg.type === 'human' ? 'sent' : 'received'}>
                  {msg.type === 'ai' && <ChatBubbleAvatar fallback={'AI'} />}
                  <ChatBubbleMessage variant={msg.type === 'human' ? 'sent' : 'received'}>
                    {msg.content}
                  </ChatBubbleMessage>
                </ChatBubble>
              ))}
            </>
          )}
          {sendMessageMutation.isPending && (
            <ChatBubble variant="sent">
              <ChatBubbleMessage variant={'sent'}>{latestInput}</ChatBubbleMessage>
            </ChatBubble>
          )}
          {sendMessageMutation.isPending && (
            <ChatBubble variant="received">
              <ChatBubbleAvatar fallback="AI" />
              <ChatBubbleMessage isLoading />
            </ChatBubble>
          )}
          <div ref={messagesEndRef} />
        </ChatMessageList>
      </div>
      <div className="absolute bottom-0 left-0 right-0 border-t bg-white pt-5">
        <div className="relative">
          <ChatInput
            placeholder="Type your message here..."
            onChange={handleChangeInput}
            onKeyDown={onKeyDown}
            value={input}
            disabled={sendMessageMutation.isPending || isLoading}
          ></ChatInput>
          <Button
            className="absolute right-2 top-1/2 flex h-10 w-10 -translate-y-1/2 transform items-center justify-center rounded-md"
            type="submit"
            size="icon"
            disabled={sendMessageMutation.isPending || isLoading || !input}
          >
            <Send className="size-6" onClick={onSend} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
