import { QueryKeys } from '@/api/entities/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import {
  httpAddCompaniesToList,
  httpAddCompaniesToMultipleLists,
  httpCreateUserList,
  httpGetUserLists,
} from '../api/UserListsAPI';
import { MultiSelectLists } from './MultiSelectLists';

function AddToListPopup({ handleModalToggle, companiesIds, unavailableLists = [], onSuccess = () => {} }) {
  const [newListName, setNewListName] = useState('');
  const [newListNote, setNewListNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [lists, setLists] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = () => {
    httpGetUserLists().then((response) => {
      setLists(response.data.data);
    });
  };

  const handleListNameChange = (e) => {
    setNewListName(e.target.value);
  };

  const handleListNotesChange = (e) => {
    setNewListNotes(e.target.value);
  };

  const handleCreateList = () => {
    if (newListName.trim().length === 0 || newListName.length < 3) {
      alert('List name should not be empty and should have at least 3 words.');
      return;
    }

    setNewListName('');
  };

  const handleSave = () => {
    setIsLoading(true);

    if (selectedLists.length > 0) {
      httpAddCompaniesToMultipleLists(
        selectedLists.map((list) => list.id),
        companiesIds,
      ).then((response) => {
        if (typeof onSuccess === 'function') {
          onSuccess();

          queryClient.resetQueries({
            queryKey: [QueryKeys.Signals],
          });
        }

        setIsLoading(false);
        handleModalToggle();
      });
    } else {
      httpCreateUserList({ name: newListName, notes: newListNote, userId: localStorage.getItem('userId') })
        .then((response) => {
          httpAddCompaniesToList(response.data.userListId, companiesIds)
            .then((response) => {
              setIsLoading(false);
              if (typeof onSuccess === 'function') {
                onSuccess();
              }

              handleModalToggle();
            })
            .catch((error) => {
              setIsLoading(false);
            });
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handlelosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      handleModalToggle();
    }
  };

  useEffect(() => {}, []);

  return (
    <div
      className="fixed left-0 top-0 z-[2000] flex h-full w-full items-center justify-center bg-black bg-opacity-50"
      id="ModelContainer"
      onClick={handlelosePopUp}
    >
      <div className="w-[80%] rounded-lg bg-base-200 lg:w-[50%]">
        <div className="flex h-10 w-full items-center justify-end rounded-tl-lg rounded-tr-lg bg-custom-gradient">
          <IoClose size={20} className="mr-2 cursor-pointer text-base-200" onClick={handleModalToggle} />
        </div>
        <div className="mt-10 flex w-full flex-col items-start justify-center gap-2 rounded-lg px-10">
          <h2 className="text-2xl font-bold">Add to my lists</h2>
          <MultiSelectLists
            lists={lists}
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
            unavailableLists={unavailableLists}
          />
        </div>

        <div
          className={`${
            selectedLists.length > 0 ? 'pointer-events-none opacity-50' : ''
          } mb-10 mt-7 flex w-full flex-col items-start justify-center rounded-lg px-10`}
        >
          <h2 className="mb-4 text-2xl font-bold">or create new list</h2>
          <div className="label">
            <span className="label-text text-base font-semibold opacity-70">List Name</span>
          </div>
          <input
            type="text"
            maxLength="255"
            placeholder="Enter list name..."
            value={newListName}
            onChange={handleListNameChange}
            className="input input-bordered mb-4 w-full"
          />
          <div className="label">
            <span className="label-text text-base font-semibold opacity-70">Notes</span>
          </div>
          <textarea
            className="textarea textarea-bordered mb-4 w-full"
            onChange={handleListNotesChange}
            placeholder="Insert notes here"
            rows="4"
          ></textarea>
        </div>

        <div className="mb-10 flex items-center justify-center gap-4">
          <button
            disabled={selectedLists.length == 0 && newListName.length < 4}
            className={`"btn w-1/2 ${
              selectedLists.length == 0 && newListName.length < 4
                ? 'bg-neutral text-base-content'
                : 'bg-custom-gradient text-base-200'
            } transition-transform" btn flex items-center shadow-md hover:scale-102`}
            onClick={handleSave}
          >
            {isLoading ? (
              <span className="loading loading-dots loading-md"></span>
            ) : (
              <>
                <FaSave size={20} />
                <span className="text-lg font-medium">Save</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddToListPopup;
