import { api } from '@/api/apiV2';
import { UserSubscriptionStateResponse } from '@/api/generated/Api';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UseUserSubscriptionArgs = {
  onSuccess?: (data: UserSubscriptionStateResponse) => void;
  signal?: AbortSignal;
};

export const useUserSubscriptionState = (args?: UseUserSubscriptionArgs) => {
  const { onSuccess, signal } = args || {};

  const { data, isFetching, ...rest } = useQuery({
    queryKey: [QueryKeys.UserSubscriptionState],
    queryFn: async () => {
      var response = await api.subscription.getUserSubscriptionState({ signal });

      onSuccess?.(response.data);

      return response.data;
    },
  });

  return {
    subscriptionState: data,
    isUserSubscriptionStateLoading: isFetching,
    ...rest,
  };
};
