import { IconName } from '@components/SvgIcon';
import { VariantProps } from 'class-variance-authority';
import { styles } from './styles';

export const BILLING_MODAL_NOTIFICATION_ICONS: Record<
  Exclude<VariantProps<typeof styles>['intent'], null | undefined>,
  IconName
> = {
  warning: 'Warning',
};
