import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import MessageLoading from './message-loading';
import { Button, ButtonProps } from '../../Button';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// ChatBubble
const chatBubbleVariant = cva('flex gap-2 max-w-[60%] items-end relative group', {
  variants: {
    variant: {
      received: 'self-start',
      sent: 'self-end flex-row-reverse',
    },
    layout: {
      default: '',
      ai: 'max-w-full w-full items-center',
    },
  },
  defaultVariants: {
    variant: 'received',
    layout: 'default',
  },
});

interface ChatBubbleProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof chatBubbleVariant> {}

const ChatBubble = React.forwardRef<HTMLDivElement, ChatBubbleProps>(
  ({ className, variant, layout, children, ...props }, ref) => (
    <div className={cn(chatBubbleVariant({ variant, layout, className }), 'group relative')} ref={ref} {...props}>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && typeof child.type !== 'string'
          ? React.cloneElement(child, { variant, layout } as React.ComponentProps<typeof child.type>)
          : child,
      )}
    </div>
  ),
);
ChatBubble.displayName = 'ChatBubble';

// ChatBubbleAvatar
interface ChatBubbleAvatarProps {
  src?: string;
  fallback?: string;
  className?: string;
}

const PLACEHOLDER = '/chatbot.png';

const ChatBubbleAvatar: React.FC<ChatBubbleAvatarProps> = ({ src, fallback, className }) => (
  <Avatar className={className}>
    <AvatarImage src={PLACEHOLDER} alt="Avatar" />
    <AvatarFallback>{fallback}</AvatarFallback>
  </Avatar>
);

// ChatBubbleMessage
const chatBubbleMessageVariants = cva('p-4', {
  variants: {
    variant: {
      received: 'bg-gray-200 text-secondary-foreground rounded-r-lg rounded-tl-lg text-sm',
      sent: 'bg-gradient-to-r from-primary-600  to-primary-200 text-base-200 rounded-l-lg rounded-tr-lg bg-[length:50vw_100%] bg-no-repeat text-sm',
    },
    layout: {
      default: '',
      ai: 'border-t w-full rounded-none bg-transparent',
    },
  },
  defaultVariants: {
    variant: 'received',
    layout: 'default',
  },
});

interface ChatBubbleMessageProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chatBubbleMessageVariants> {
  isLoading?: boolean;
}

const ChatBubbleMessage = React.forwardRef<HTMLDivElement, ChatBubbleMessageProps>(
  ({ className, variant, layout, isLoading = false, children, ...props }, ref) => (
    <div
      className={cn(
        chatBubbleMessageVariants({ variant, layout, className }),
        'max-w-full whitespace-pre-wrap break-words',
      )}
      ref={ref}
      {...props}
    >
      {isLoading ? (
        <div className="flex items-center space-x-2">
          <MessageLoading />
        </div>
      ) : (
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            ul: ({ node, ...props }) => <ul className="list-disc space-y-2 pl-3" {...props} />,
            ol: ({ node, ...props }) => <ol className="list-decimal space-y-2 pl-6" {...props} />,
            li: ({ node, ...props }) => <li className="ml-3" {...props} />,
          }}
        >
          {children as string}
        </ReactMarkdown>
      )}
    </div>
  ),
);
ChatBubbleMessage.displayName = 'ChatBubbleMessage';

// ChatBubbleTimestamp
interface ChatBubbleTimestampProps extends React.HTMLAttributes<HTMLDivElement> {
  timestamp: string;
}

const ChatBubbleTimestamp: React.FC<ChatBubbleTimestampProps> = ({ timestamp, className, ...props }) => (
  <div className={cn('mt-2 text-right text-xs', className)} {...props}>
    {timestamp}
  </div>
);

// ChatBubbleAction
type ChatBubbleActionProps = ButtonProps & {
  icon: React.ReactNode;
};

const ChatBubbleAction: React.FC<ChatBubbleActionProps> = ({ icon, onClick, className, size = 'icon', ...props }) => (
  <Button size={size} className={className} onClick={onClick} {...props}></Button>
);

interface ChatBubbleActionWrapperProps {
  variant: 'sent' | 'received';
  className?: string;
  children: React.ReactNode;
}

// ChatBubbleActionWrapper
const ChatBubbleActionWrapper: React.FC<ChatBubbleActionWrapperProps> = ({ variant, className, children }) => (
  <div
    className={cn(
      'absolute top-1/2 flex -translate-y-1/2 opacity-0 transition-opacity duration-200 group-hover:opacity-100',
      variant === 'sent' ? '-left-1 -translate-x-full flex-row-reverse' : '-right-1 translate-x-full',
      className,
    )}
  >
    {children}
  </div>
);

export {
  ChatBubble,
  ChatBubbleAvatar,
  ChatBubbleMessage,
  ChatBubbleTimestamp,
  chatBubbleVariant,
  chatBubbleMessageVariants,
  ChatBubbleAction,
  ChatBubbleActionWrapper,
};
