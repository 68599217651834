import { useDetachPaymentMethod } from '@/api/entities/paymentMethods';
import { Trash2 } from 'lucide-react';
import { FC } from 'react';

type DetachPaymentMethodButtonProps = {
  id: string;
  disabled?: boolean;
};

export const DetachPaymentMethodButton: FC<DetachPaymentMethodButtonProps> = ({ id, disabled }) => {
  const { detachPaymentMethod, isPaymentMethodDetaching } = useDetachPaymentMethod();

  const handleClick = async () => await detachPaymentMethod(id);

  return (
    <button
      disabled={disabled || isPaymentMethodDetaching}
      onClick={handleClick}
      className="size-fit justify-self-end p-1 delay-100 hover:scale-125 disabled:pointer-events-none disabled:opacity-50 motion-safe:transition-transform motion-safe:duration-200 motion-reduce:transform-none"
    >
      <Trash2 size={16} />
    </button>
  );
};
