import { DefinedInitialDataOptions, QueryKey, useQuery } from '@tanstack/react-query';

type FeatureQueryResponse<T> = T & {
  isUnlocked?: boolean;
};

type UseFeatureQueryArgs<TData> = {
  queryKey: QueryKey;
  queryFn: () => Promise<FeatureQueryResponse<TData>>;
  options?: Omit<
    DefinedInitialDataOptions<FeatureQueryResponse<TData> | undefined, unknown, FeatureQueryResponse<TData>, QueryKey>,
    'queryKey' | 'queryFn'
  >;
  statusCodes?: number[];
};

export const useFeatureQuery = <TData>({
  queryFn,
  queryKey,
  options,
  statusCodes = [409],
}: UseFeatureQueryArgs<TData>) => {
  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const response = await queryFn();

        return {
          ...response,
          isUnlocked: true,
        };
      } catch (error: any) {
        if (statusCodes.includes(error.response?.status)) {
          return {
            isUnlocked: false,
          } as FeatureQueryResponse<TData>;
        }

        return {
          isUnlocked: true,
        } as FeatureQueryResponse<TData>;
      }
    },
    ...options,
  });

  return {
    ...queryResult,
    isUnlocked: queryResult.data?.isUnlocked,
  };
};
