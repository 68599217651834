import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../queryKeys';

export const useDetachPaymentMethod = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationKey: [MutationKeys.SetDefaultPaymentMethod],
    mutationFn: async (id: string) => {
      await api.paymentMethods.detachPaymentMethod(id);

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.PaymentMethods],
      });
    },
  });

  return {
    isPaymentMethodDetaching: isPending,
    detachPaymentMethod: mutateAsync,
  };
};
